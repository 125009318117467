import * as React from "react";
import * as Portal from "Components/Framework/Page/CommonPage";
import { CommonList, CommonListCache, CommonListProps, renderStandardCell } from "Components/Framework/CommonList/CommonList";
import { Authorization, AuthorizedRole } from "Auth/Authorization";
import { UserManagementService } from 'Services/UserManagementService';
import { ScenarioService } from 'Services/ScenarioService';
import { EditUserPage } from "./EditUserPage";
import { UserContext } from 'Auth/UserContext';
import { EmptyUserListApiModel, EmptyUserListUIModel, UserListApiModel, UserListUIModel } from 'Models/UserListModel';
import type { DynamicFilterDefinition } from "Components/Framework/CommonList/Filters/DynamicFilterTypes";
import type { PagingParams } from "Components/Framework/CommonList/ListPaging";
import { LocationFilterDefinition } from "Components/Framework/CommonList/Filters/LocationFilterDefinition";
import { Link } from '@fluentui/react';
import "./UserManagement.css";
import {
    Button,
    TableCellLayout,
    TableColumnDefinition,
    createTableColumn
} from "@fluentui/react-components";
import { DismissRegular } from "@fluentui/react-icons";
import { LocationService } from "../../../Services/LocationService";
import { LocationModel } from "../../../Models/LocationModel";
import { ScenarioResponseModel } from "../../../Models/ScenarioResponseModel";
import { CommonUtils } from "../../../Utilities/CommonUtils";
import { TracingService } from "../../../Services/TracingService";
import ResourceManager from "../../../Resources/ResourceManager";
import { ResourceStrings } from "../../../Resources/ResourceKeys";
import { Constants } from "Utilities/Constants";

let userManagementService = UserManagementService.getInstance();
let tracingService = TracingService.getInstance();

const Component_Name = 'User Management';
let AlertMessage = ResourceManager.GetString(ResourceStrings.UpdateUserKeys.UnAuthorized);
let Error_Fetch = 'Error retrieving users.'; // localize
let scenarioOptions: string[];
var scenarioData: ScenarioResponseModel[];

const debugging = false;
const debug = (message: string) => {
    if (debugging) {
        console.log("[" + Component_Name + "] " + message);
    }
};
/**
 * Pages are defined as class components to leverage interface implementation for clean standardized code.
 * This design pattern simplifies readability and code reviews, allowing feature development to focus on feature logic.
 * Every page must:
 *      1. Define pageProps
 *      2. Use the CommonPage component to wrap the UI of the page content
 *      3. Pass the pageProps to the CommonPage component
 */
interface IUserManagementListState {
    items: any[];
    selectedUser: UserListApiModel;
    selectedItemShouldOpen: boolean;
    isLoading: boolean;
    isRefreshing: boolean;
    isError: boolean;
    totalItemCount: number;
    scenarioData: [];
    refreshItems: any[];
    loggedInUserDetails: any;
    metroMetadata: LocationModel[];
    scenarioOptions: string[];
    locationFilter: DynamicFilterDefinition;
};

export class UserManagementListPage extends React.Component<{}, IUserManagementListState> implements Portal.ICommonPage {
    // Continuation token 
    private contToken: string = "";
    // Required member for a page
    public pageProps: Portal.CommonPageProps = {
        authRequired: true,
        pageTitle: "User Management",
        authorizedRoles: [
            AuthorizedRole.OrganizationAdministrator,
            AuthorizedRole.SiteAdministrator,
            AuthorizedRole.CapacityManager,
            AuthorizedRole.DeviceManager,
            AuthorizedRole.User,
            AuthorizedRole.ScenarioOwner,
            AuthorizedRole.AccountManager,
            AuthorizedRole.ScenarioChamp,
            AuthorizedRole.ScenarioUser,
            AuthorizedRole.SupportUser
        ]
    };

    private isError = false;
    private paging: PagingParams = {
        pageSize: 100,
        batchSize: 600,
        token: "",
        totalItemCount: 0
    };

    private userCache: CommonListCache = new CommonListCache();
    private userCacheAPIUsers: CommonListCache = new CommonListCache();
    private activeFilters: { key: string, value: string }[] = [];
    private locationDef: LocationFilterDefinition;

    constructor(props: {}) {
        super(props);
        scenarioData = ScenarioService.getInstance().GetScenarioData();
        this.locationDef = new LocationFilterDefinition("user"); // pass user Id token
        this.state = {
            selectedUser: EmptyUserListApiModel,
            selectedItemShouldOpen: false,
            isError: false,
            items: [],
            totalItemCount: 0,
            refreshItems: [],
            loggedInUserDetails: [],
            metroMetadata: [],
            isLoading: false,
            isRefreshing: false,
            scenarioOptions: [],
            scenarioData: [],
            locationFilter: this.locationDef.createLocationFilterDefinition(1)
        };
    }

    static contextType = UserContext;

    private setTenantPagingSettings() {
        const user = this.context;
        if (user.TenantId === Constants.getInstance().xdcTenantId) {
            this.paging.pageSize = 50;
            this.paging.batchSize = 150;
        }
    };

    private Authorize(user: any): boolean {
        let userHasUserManagementPermissions = Authorization.AuthorizeUser(user.roles, [
            AuthorizedRole.OrganizationAdministrator,
            AuthorizedRole.AccountManager,
            AuthorizedRole.SiteAdministrator,
            AuthorizedRole.ScenarioManager,
            AuthorizedRole.ScenarioChamp,
            AuthorizedRole.ScenarioOwner,
            AuthorizedRole.ScenarioUser,
            AuthorizedRole.SupportUser
        ]);
        return user.IdToken && userHasUserManagementPermissions;
    }

    private GetCacheAsUIModel = () => {
        return UserManagementService.ApiUsersToUIUsers(this.userCache.Read());
    };

    private FetchUsersAsync = async (hardRefresh?: boolean) => {
        const user = this.context;
        if (this.Authorize(user)) {
            return userManagementService.GetUserListData(user.IdToken, this.activeFilters, hardRefresh ? "" : this.paging.token, this.paging.batchSize)
                .then(results => {
                    this.paging.totalItemCount = results.totalRecords;
                    this.paging.token = results.countiToken;
                    debug("FetchUsersAsync: " + results.totalRecords + " total users.");
                    return results.users;
                })
                .catch(error => {
                    tracingService.trace(Component_Name, error);
                    // server returns error 409 conflict when all results have been returned because the continuation token is no longer valid (null).
                    // exception needs to be logged when server returns 409 but the cache is still less than the total records promised.
                    // ex: total records reported: 308, total records returned: 302, cache will never be considered "full" due to discrepency.
                    AlertMessage = Error_Fetch;
                    this.isError = true;
                    return [];
                });
        }
        return [];
    };

    private RefreshListAsync = async () => {
        this.userCache.Clear();
        this.paging.token = "";
        this.setState({
            isLoading: true,
            isRefreshing: true,
            items: []
        });

        debug("RefreshListAsync: refreshing");
        await this.FetchUsersAsync(true).then(users => {
            if (users.length > 0) {
                this.userCache.Append(users);
            }

            this.setState({
                items: UserManagementService.ApiUsersToUIUsers(users),
                totalItemCount: this.paging.totalItemCount,
                isLoading: false,
                isRefreshing: false,
                selectedUser: EmptyUserListApiModel,
                selectedItemShouldOpen: false,
                isError: this.isError
            });
            debug("RefreshListAsync: " + users.length + " users.");
        });
    };

    private FetchNextCacheBatchAsync = async () => {
        if (this.userCache.Read().length < this.state.totalItemCount) {
            this.userCache.Append(await this.FetchUsersAsync(false));
            //this.userCache.Append(await this.FetchUsersAsync(this.userCache.Read().length));
            debug("Batched users.");
        }
    };

    GetUserByAadId = async () => {
        const user = this.context;
        await userManagementService
            .GetUserByAadObjectId(
                user.IdToken,
                user.userObjectID
            ).then((results) => {
                this.setState({ loggedInUserDetails: results.message });
                var val: any[] = [];
                this.state.loggedInUserDetails.scenarioIds.forEach((scenario: string) => {
                    val.push(CommonUtils.convertScenarioIdToNameMapping(scenarioData).get(scenario));
                });
                scenarioOptions = val;
            });
    };

    GetMetroMetaData = () => {
        const user = this.context;
        LocationService.getInstance().GetLocationData(user.IdToken).then((results) => {
            this.setState({ metroMetadata: results });
        });

        this.locationDef.fetchLocationData(user.IdToken).then(res => {
            this.setState({
                locationFilter: this.locationDef.createLocationFilterDefinition(1)
            });
        });
    };

    private loggedInUserWithOrgAdminRole = (user: {
        roles: string[];
        IdToken: string;
    }) => {
        return Authorization.AuthorizeUser(user.roles, [AuthorizedRole.OrganizationAdministrator]);
    };

    fetchScenarioByRole = () => {
        const user = this.context;
        if (this.loggedInUserWithOrgAdminRole(user)) {
            scenarioOptions = ["CE ABB",
                "CE ATS",
                "CE Eaton",
                "CE Microsoft Ceit",
                "CE Johnson Controls",
                "CE Lantronix",
                "CE Munster",
                "CE Siemens",
                "Server Dell",
                "Server ZT",
                "SMTK",
                "Soc Lenel",
                "Mixed Reality Hololens",
                "Server HPE",
                "CE Others",
                "DC Robot Research",
                "Quanta",
                "Digital Procedures",
                "Valyrian Lab"];
        } else {
            this.GetUserByAadId();
        }
    };

    private async loadingDelay() {
        await new Promise(resolve => setTimeout(resolve, 500));
    };

    private doSearch = (value: string) => {
        // Cancelling the search forces a full refresh
        if (value === "") {
            this.RefreshListAsync();
        }
        else {
            this.setState({
                isLoading: true,
                isRefreshing: true,
                items: []
            });

            this.loadingDelay().then(async res => {
                const cacheFull = this.userCache.Read().length === this.paging.totalItemCount ? true : false;
                let filteredUsers: UserListApiModel[] = [];

                if (cacheFull) {
                    // find similar matches
                    filteredUsers = this.userCache.Read().filter(user => user.userPrincipalName !== null ? user.userPrincipalName.toUpperCase().includes((value.toUpperCase())) : null);
                }
                else {
                    // Search API if cache is not full
                    this.activeFilters.push({ key: "upn", value: value });
                    filteredUsers = await this.FetchUsersAsync(true);
                    this.activeFilters = this.activeFilters.filter((filters) => filters.key !== "upn");
                    this.userCacheAPIUsers.Append(filteredUsers);
                }

                this.paging.totalItemCount = filteredUsers.length;
                this.setState({
                    items: UserManagementService.ApiUsersToUIUsers(filteredUsers),
                    totalItemCount: this.paging.totalItemCount,
                    isLoading: false,
                    isRefreshing: false,
                    selectedUser: EmptyUserListApiModel,
                    selectedItemShouldOpen: false
                });
            });
        }
    };

    private HandleFilterChange = (filters: { key: string, value: string | any[] }[]) => {
        this.activeFilters = filters.map(f => {
            return { key: f.key, value: f.value.toString() };
        });

        this.RefreshListAsync();
    };

    private columns: TableColumnDefinition<UserListUIModel>[] = [

        createTableColumn<UserListUIModel>({
            columnId: "userPrincipalName",
            renderHeaderCell: () => {
                return "User Principal Name (UPN)";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout title={item.userPrincipalName} truncate>
                        <Link onClick={() => this.handleClick(item)}>{item.userPrincipalName}</Link>
                    </TableCellLayout>
                );
            }
        }),
        createTableColumn<UserListUIModel>({
            columnId: "displayName",
            renderHeaderCell: () => {
                return "Display Name";
            },

            renderCell: (item) => {
                return renderStandardCell(item.displayName, item.displayName);
            }
        }),
        createTableColumn<UserListUIModel>({
            columnId: "firstName",
            renderHeaderCell: () => {
                return "First Name";
            },
            renderCell: (item) => {
                return renderStandardCell(item.firstName);
            }
        }),
        createTableColumn<UserListUIModel>({
            columnId: "lastName",
            renderHeaderCell: () => {
                return "Last Name";
            },
            renderCell: (item) => {
                return renderStandardCell(item.lastName);
            }
        }),
        createTableColumn<UserListUIModel>({
            columnId: "email",
            renderHeaderCell: () => {
                return "User Email";
            },
            renderCell: (item) => {
                return renderStandardCell(item.email, item.email);
            }
        }),
        createTableColumn<UserListUIModel>({
            columnId: "scenarioIds",
            renderHeaderCell: () => {
                return "Scenarios";
            },
            renderCell: (item) => {
                var r = "";
                if (item.scenarioIds !== null) {
                    for (var i = 0; i < item.scenarioIds.length; i++) {
                        if (r === "") {
                            r = item.scenarioIds[i];
                        } else {
                            r = r + ", " + item.scenarioIds[i];
                        }
                    }
                }
                
                return renderStandardCell(r, r);
            }
        }),
        createTableColumn<UserListUIModel>({
            columnId: "metro",
            renderHeaderCell: () => {
                return "Metro";
            },
            renderCell: (item) => {
                var values: any[] = [];
                for (var i in item.metro) {
                    values.push(item.metro[i]);
                }
                var r;
                if (values.length > 0) {
                    r = values[0];
                }

                return renderStandardCell(r, r);
            }
        }),
        createTableColumn<UserListUIModel>({
            columnId: "roles",
            renderHeaderCell: () => {
                return "Roles";
            },
            renderCell: (item) => {
                var r = "";
                if (item.roles !== null) {
                    for (var i = 0; i < item.roles.length; i++) {
                        if (r === "") {
                            r = item.roles[i];
                        } else {
                            r = r + ", " + item.roles[i];
                        }
                    }
                }

                return renderStandardCell(r, r);
            }
        }),
        createTableColumn<UserListUIModel>({
            columnId: "status",
            renderHeaderCell: () => {
                return "Status";
            },
            renderCell: (item) => {
                var displayText = (item.accountEnabled ?? true) ? "Enabled" : "Disabled";
                
                return renderStandardCell(displayText, displayText);
            }
        })
    ];


    handleClick(item: UserListUIModel) {
        var clickedUser = this.GetUserApiModelFromCache(item);
        if (clickedUser.id === "") {
            clickedUser = this.userCacheAPIUsers.Read().find((user) => user.userPrincipalName === item.userPrincipalName &&
                user.email === item.email
            );
            this.userCacheAPIUsers.Clear();
        }
        if (clickedUser !== this.state.selectedUser && !this.state.selectedItemShouldOpen) {
            this.setState({
                selectedUser: clickedUser,
                selectedItemShouldOpen: true
            });
        }
        else {
            this.setState({ selectedItemShouldOpen: false });
        }
    };

    private GetUserApiModelFromCache(uiUser: UserListUIModel): UserListApiModel {
        const cachedUser = this.userCache.Read().find((user) => user.userPrincipalName === uiUser.userPrincipalName &&
            user.email === uiUser.email
        );
        return cachedUser! ?? EmptyUserListUIModel;
    };

    private CloseSelectedItem = (ev: React.MouseEventHandler<HTMLDivElement>) => {
        this.RefreshListAsync();
    };

    private CloseMessageBox = () => {
        AlertMessage = "trr";
        this.setState({
            isError: false
        });
    };

    componentDidMount() {
        this.fetchScenarioByRole();
        this.GetMetroMetaData();
        this.RefreshListAsync();
    };

    render() {
        this.setTenantPagingSettings();

        const listProps: CommonListProps = {
            dataGridProps: {
                items: this.state.items,
                columns: this.columns,
                sortable: true
            },
            isLoading: this.state.isLoading,
            isRefreshing: this.state.isRefreshing,
            itemCountName: "users",
            onRefresh: this.RefreshListAsync,
            searchProps: {
                columnId: this.columns[0].columnId.toString(),
                useCache: true,
                onSearch: this.doSearch,
                placeholder: "Filter UPN"
            },
            filterProps: {
                definitions: [
                    this.state.locationFilter,
                    {
                        label: "Scenarios",
                        key: "scenario",
                        options: scenarioOptions
                    },
                    {
                        label: "Roles",
                        key: "roles",
                        options: ["Scenario Owner", "Scenario Champ", "Scenario User", "Organization Administrator"]
                    }
                ],
                onFiltersUpdate: this.HandleFilterChange
            },
            pagingProps: {
                pageSize: this.paging.pageSize,
                totalCount: this.state.totalItemCount,
                onFetchNextBatch: this.FetchNextCacheBatchAsync,
                getCache: this.GetCacheAsUIModel
            }
        };

        if (this.state.selectedItemShouldOpen) {
            return (<EditUserPage user={this.state.selectedUser} onClose={this.CloseSelectedItem} loggedInUserDetails={this.state.loggedInUserDetails} metroMetadata={this.state.metroMetadata} />);
        }
        else {
            return (
                <Portal.CommonPage {...this.pageProps}>
                    <div className="user-list-error" style={{ display: this.state.isError ? "block" : "none" }}>
                        <Button
                            style={{ float: 'right' }}
                            onClick={this.CloseMessageBox}
                            appearance="transparent"
                            icon={<DismissRegular />}
                            size="small"
                        />
                        {AlertMessage}
                    </div>
                    <CommonList {...listProps} ></CommonList>
                </Portal.CommonPage>
            );
        }
    }
}