import { ScenarioResponseModel, IScenario, Scenario } from 'Models/ScenarioResponseModel';
import { Constants } from '../Utilities/Constants';
import { ApplicationInsightsLogger } from './Logger';
import { TracingService } from './TracingService';
import * as ServiceUtils from './ServiceUtils';

const constants = Constants.getInstance();
const Component_name = 'ScenarioService';
let tracingService = TracingService.getInstance();

export class ScenarioService {
    private static instance: ScenarioService;
    private cachedScenarios: Map<string, Scenario>;

    private constructor() {
        this.cachedScenarios = new Map<string, Scenario>();
    }

    public static getInstance = () => {
        if (!ScenarioService.instance) {
            ScenarioService.instance = new ScenarioService();
        }
        return ScenarioService.instance;
    };

    /**
     * (preferred) Get scenarios from API. Including scenario variants.
     * @param token - user token
     * @returns Scenario objects.
     */
    public GetScenariosAsync = async (token: string): Promise<Array<Scenario>> => {
        tracingService.trace(Component_name, 'GetListScenarioAPICall');

        try {
            var response = await fetch(constants.ListScenarioApiEndpoint, {
                method: ServiceUtils.HttpGet,
                headers: ServiceUtils.CreateDefaultRequestHeaders(token, constants.CorrelationID)
            });

            const data = await response.json();

            if (response.ok) {
                var scenarioResponse: IScenario[] = data;
                var results = new Array<Scenario>();
                if (scenarioResponse) {
                    scenarioResponse.forEach(s => results.push(new Scenario(s.id, s.name, s.description, s.variants)));
                }
                this.UpdateCachedScenarios(results);

                return results;
            }
            else {
                const errorMsg = (data && data.error.message) || response.statusText;
                return Promise.reject(errorMsg);
            }
        }
        catch (error) {
            console.log("GetListScenarioAPICall: Error occurred with correlationID: " + constants.CorrelationID);
            tracingService.traceHttpResponse(error);
            ApplicationInsightsLogger.getInstance().logException(error);
            return Promise.reject(error);
        };
    };

    public GetScenarioFromCache(id: string): Scenario | undefined {
        return this.cachedScenarios.get(id);
    }

    private UpdateCachedScenarios(scenarios: Array<Scenario>) {
        if (!this.cachedScenarios) {
            this.cachedScenarios = new Map<string, Scenario>();
        }

        scenarios.forEach(scenario => this.cachedScenarios.set(scenario.id, scenario));
    }
    public GetCreateUserScenariosAsync = async (token: string): Promise<ScenarioResponseModel[]> => {
        tracingService.trace(Component_name, 'GetListScenarioAPICall');
        var scenarioPortalOutputs: ScenarioResponseModel[] = [];
        var ListScenarioEndPoint = constants.ListScenarioApiEndpoint;
        try {
            var response = await fetch(ListScenarioEndPoint, {
                method: 'get',
                headers: {
                    'content-type': 'application/json',
                    'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
                    'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
                    'correlationId': constants.CorrelationID,
                    Authorization: constants.Bearer + token
                }
            });
            if (!response.ok) {
                const data = await response.json();
                const errorMsg = (data && data.error.message) || response.statusText;
                return Promise.reject(errorMsg);
            }
            const data: ScenarioResponseModel[] = await response.json();
            scenarioPortalOutputs = ScenarioService.ScenarioResponseToPortalUI(data);
        } 
        catch (error) {
            console.log("Error occurred with correlationID: " + constants.CorrelationID);
            tracingService.traceHttpResponse(error);
            ApplicationInsightsLogger.getInstance().logException(error);
            return Promise.reject(error);
        }
        
        return scenarioPortalOutputs;
    };

    // Hardcoded
    public GetScenariosV1Async = async (token: string): Promise<ScenarioResponseModel[]> => {
        tracingService.trace(Component_name, 'GetListScenarioAPICall');
        return this.GetScenarioData();
    };

    private static ScenarioResponseToPortalUI = (scenarioResponses: ScenarioResponseModel[]) => {
        var scenarioResponseModel: ScenarioResponseModel[] = [];
        if (scenarioResponses) {
            scenarioResponses.forEach(function (scenarioData) {
                scenarioResponseModel.push(
                    new ScenarioResponseModel(
                        scenarioData.id,
                        scenarioData.name,
                        scenarioData.supportedCompanyMetaData?.map(metaData => [metaData.companyId, metaData.companyName, metaData.allowedUserEmailDomains]) || []
                    )
                );
            });
        }
        return scenarioResponseModel;
    };

    //Todo: fetch data from scneario api once the api is live
    public GetScenarioData = (): ScenarioResponseModel[] => {
        var ScenarioResponseModelOutput: ScenarioResponseModel[] = [];
    
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('CeAbb', 'CE ABB'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('CeAts', 'CE ATS'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('CeEaton', 'CE Eaton'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('CeMicrosoftCeit', 'CE Microsoft Ceit'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('CeJohnsonControls', 'CE Johnson Controls'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('CeLantronix', 'CE Lantronix'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('CeMunster', 'CE Munster'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('CeSiemens', 'CE Siemens'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('ServerDell', 'Server Dell'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('ServerZt', 'Server ZT'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('ServerMicrosoftDct', 'SMTK'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('SocLenel', 'Soc Lenel'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('MixedRealityHololens', 'Mixed Reality Hololens'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('ServerHpe', 'Server HPE'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('CeOthers', 'CE Others'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('DcRobotResearch', 'DC Robot Research'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('Quanta', 'Quanta'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('DigitalProcedures', 'Digital Procedures'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('ValyrianLab', 'Valyrian Lab'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('ServerOracle', 'Server Oracle'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('ServerHpeHpc', 'Server HPE HPC'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('ServerLenovoDatabox', 'Server Lenovo Databox'));

    
        return ScenarioResponseModelOutput;
    };

    // This is hardcoded specifically for Device Onboarding because
    // Lenel in the backend is represented as 1 scenario group and multiple tags.
    public GetDeviceOnboardingScenarios = (): ScenarioResponseModel[] => {
        var ScenarioResponseModelOutput: ScenarioResponseModel[] = [];

        ScenarioResponseModelOutput.push(new ScenarioResponseModel('CeAbb', 'CE ABB'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('CeAts', 'CE ATS'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('CeEaton', 'CE Eaton'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('CeMicrosoftCeit', 'CE Microsoft Ceit'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('CeJohnsonControls', 'CE Johnson Controls'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('CeLantronix', 'CE Lantronix'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('CeMunster', 'CE Munster'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('CeSiemens', 'CE Siemens'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('CeSchneider', 'CE Schneider'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('CeOthers', 'CE Others'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('DcRobotResearch', 'DC Robot Research'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('DigitalProcedures', 'Digital Procedures'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('MixedRealityHololens', 'Mixed Reality Hololens'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('Lenel-AMER-V8', 'Lenel-AMER-V8'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('Lenel-APAC-V8', 'Lenel-APAC-V8'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('Lenel-EMEA-V8', 'Lenel-EMEA-V8'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('Lenel-Global-V8', 'Lenel-Global-V8'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('ServerDell', 'Server Dell'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('ServerHpe', 'Server HPE'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('ServerMicrosoftDct', 'SMTK'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('ServerZt', 'Server ZT'));        
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('Quanta', 'Quanta'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('ValyrianLab', 'Valyrian Lab'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('ServerOracle', 'Server Oracle'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('ServerHpeHpc', 'Server HPE HPC'));
        ScenarioResponseModelOutput.push(new ScenarioResponseModel('ServerLenovoDatabox', 'Server Lenovo Databox'));

        return ScenarioResponseModelOutput;
    };

    public static MapScenarioName(scenarioIds: string[], scenarioData: ScenarioResponseModel[] ): string {
        var ScenarioNameList: string = "";

        scenarioIds.forEach(function (scenarioId) {
            if (ScenarioNameList !== "") {
                ScenarioNameList = ScenarioNameList + ", ";
            }
            ScenarioNameList = ScenarioNameList + scenarioData.filter(p => p.id === scenarioId).map((x: ScenarioResponseModel) => x.name);
        });

        return ScenarioNameList;
    }

    public static MapUserScenarioName(scenarioIds: string[], scenarioData: ScenarioResponseModel[]): string[] {
        var ScenarioNameList: string = "";
        var ScenarioList: string[] = [];
        scenarioIds?.forEach(function (scenarioId) {
            var s = scenarioData.filter(p => p.id === scenarioId).map((x: ScenarioResponseModel) => x.name);
            ScenarioList.push(s[0]);
            if (ScenarioNameList !== "") {
                ScenarioNameList = ScenarioNameList + ", ";
            }
            ScenarioNameList = ScenarioNameList + scenarioData.filter(p => p.id === scenarioId).map((x: ScenarioResponseModel) => x.name);
        });
        return ScenarioList;
    }
}

