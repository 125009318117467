import * as React from 'react';
import {
    Label,
    IDropdownOption,
    Dropdown,
    PrimaryButton,
    DefaultButton,
    IStackTokens,
    Stack
} from '@fluentui/react';
import {
    MessageBar,
    MessageBarType
} from "office-ui-fabric-react";
import { DeviceApiModel} from 'Models/DeviceModel';
import { DeviceInventoryService } from 'Services/DeviceInventoryService';
import { ResourceStrings } from 'Resources/ResourceKeys';
import { Scenario, ScenarioVariantType, ScenarioHelper } from "../../../Models/ScenarioResponseModel";
import { ScenarioService } from 'Services/ScenarioService';
import { TracingService } from 'Services/TracingService';
import { UserContext } from 'Auth/UserContext';
import Loading from "Images/Loading.gif";
import ResourceManager from 'Resources/ResourceManager';
import { Helper } from 'Utilities/Helper';
import "scss/site.css";

const Component_Name = 'Device Details';
const DetailName_SerialNumber = ResourceManager.GetString(ResourceStrings.Device.SerialNumber);
const DetailName_CurrentType = ResourceManager.GetString(ResourceStrings.Common.Current) + ' ' + ResourceManager.GetString(ResourceStrings.Device.Scenario);
const DetailName_NewType = ResourceManager.GetString(ResourceStrings.Common.New) + ' ' + ResourceManager.GetString(ResourceStrings.Device.Scenario);
const DetailName_Status = ResourceManager.GetString(ResourceStrings.Device.DeviceState);
const ScenarioIdsPlaceholder = ResourceManager.GetString(ResourceStrings.Device.ScenarioIdsPlaceholder);
const ReassignScenario_SuccessMessage = ResourceManager.GetString(ResourceStrings.DeviceAction.ReassignScenario.Message.Success);
const ReassignmentFormTitle = ResourceManager.GetString(ResourceStrings.DeviceAction.ReassignScenario.Title);
const ScenarioElementId = "ScenarioIds";
const VariantElementId = "Variant";
const FormDescription = ResourceManager.GetString(ResourceStrings.DeviceAction.ReassignScenario.Description);

let tracingService = TracingService.getInstance();
let deviceInventoryService = DeviceInventoryService.getInstance();

interface IDeviceReassigntFormProps {
    device: DeviceApiModel;
    onClose: (success: boolean) => any;
}

interface IDeviceReassignmentFormState {
    selectedScenario: string;
    selectedVariantOption: string | undefined;
    scenarioOptions: IDropdownOption[];
    variantOptions: IDropdownOption[];
    variantName: string;
    isLoading: boolean;
    error: boolean;
    showMessageBar: boolean;
    apiResponse: string;
    apiSuccess: boolean;
    formInvalid: boolean;
    formErrors: {
        variant: string;
    }
}

export class ReassignDeviceForm extends React.Component<IDeviceReassigntFormProps, IDeviceReassignmentFormState> {
    private currentDeviceScenarioId: string;
    private allScenarios: Scenario[] = [];

    constructor(props: IDeviceReassigntFormProps) {
        super(props);
        this.currentDeviceScenarioId = this.props.device.scenarioIds
            ? (this.props.device.scenarioIds.length > 0 ? this.props.device.scenarioIds[0] : "")
            : "";

        this.state = {
            selectedScenario: "",
            selectedVariantOption: "",
            scenarioOptions: [],
            variantOptions: [],
            variantName: "",
            isLoading: false,
            error: false,
            showMessageBar: false,
            apiResponse: "",
            apiSuccess: false,
            formInvalid: true,
            formErrors: {
                variant: ""
            }
        };
    }

    static contextType = UserContext;

    private ReassignDeviceAsync = async () => {
        const user = this.context;

        await deviceInventoryService.ReassignDevice(user.IdToken, this.props.device.id, this.state.selectedScenario, this.state.selectedVariantOption)
        .then(result => {
            this.setState({
                error: false,
                apiResponse: result.message,
                showMessageBar: true,
                isLoading: false
            });
        })
        .catch(error => {
            this.setState({
                error: true,
                apiResponse: error.message,
                showMessageBar: true,
                isLoading: false
            });
        });
    };

    private LoadScenarios = async () => {
        const user = this.context;

        this.allScenarios = await ScenarioService.getInstance().GetScenariosAsync(user.IdToken);
        const scenario = this.allScenarios.filter(s => s.id === this.currentDeviceScenarioId)[0];
        const currentScenarioSupportsVariants = scenario.GetVariants(ScenarioVariantType.Device).length > 0;

        // Scenario dropdown will not have this device's current scenario as an option, if the scenario doesn't support variants.
        this.setState( {
            scenarioOptions: ScenarioHelper.ScenariosToDropdownOptions(currentScenarioSupportsVariants ? this.allScenarios : this.allScenarios.filter(s => s.id !== this.currentDeviceScenarioId))
        });
    };

    private HandleSubmit = () => {
        this.setState({ isLoading: true });
        this.ReassignDeviceAsync();
    };

    private HandleDropdownChange = (
        event: React.FormEvent<
            HTMLInputElement | HTMLDivElement | HTMLTextAreaElement
        >,
        value: string | undefined
    ): void => {
        const target = event.target as HTMLInputElement;

        if (target.id === ScenarioElementId) {
            this.handleScenarioChange(value);
            return;
        }
        else if (target.id === VariantElementId) {
            this.handleVariantChange(value);
            return;
        }
    };

    handleScenarioChange = (scenarioId: string | undefined) => {
        const scenario = this.allScenarios.filter(s => s.id === scenarioId)[0];
        const variants = scenario.GetVariants(ScenarioVariantType.Device);
        if (variants.length > 0) {
            var variant = variants[0];
            let options = variant.GetOptions().sort((a, b) => a.text < b.text ? -1 : 1).map(opt =>  {
                let option: IDropdownOption = {
                    key: opt.key,
                    text: opt.text,
                    disabled: this.props.device.tagIds?.includes(opt.key)
                };
                return option;
            });

            this.setState({
                selectedScenario: scenarioId!,
                variantOptions: options,
                selectedVariantOption: undefined,
                variantName: variant.name,
                formInvalid: true
            });

            return;
        }

        this.setState({
            selectedScenario: scenarioId!,
            variantOptions: [],
            selectedVariantOption: "",
            formInvalid: this.currentDeviceScenarioId === scenarioId,
            formErrors: {
                variant: ""
            }
        });
    };

    handleVariantChange = (optionId: string | undefined) => {
        const variantOptionSameAsCurrent = this.props.device.tagIds?.includes(optionId!);
        const errorMessage = Helper.stringFormat(
            ResourceStrings.DeviceAction.ReassignScenario.FormError.FieldUnchanged,
            this.state.variantName
        );

        this.setState({
            selectedVariantOption: optionId,
            formInvalid: variantOptionSameAsCurrent,
            formErrors: {
                variant: variantOptionSameAsCurrent ? errorMessage : ""
            }
        });
    };

    private CloseForm = () => {
        if (!this.state.error && this.state.apiResponse !== "") {
            this.props.onClose(true);
        }
        else {
            this.props.onClose(false);
        }
    };

    private CloseMessageBar = () => {
        this.setState({
            error: false,
            apiResponse: "",
            showMessageBar: false,
            isLoading: false
        });
    };

    componentDidMount() {
        tracingService.trace(Component_Name, 'Displaying device reassignment form');
        this.LoadScenarios();
    }

    public render(): JSX.Element {
        const stackTokens: IStackTokens = { childrenGap: 15 };

        return (
            <div className="wrapper">
                <div className="securedevicesform">
                    <Label className="securedevicesform-title">{ ReassignmentFormTitle }</Label>
                        <div className="securedevicesform-body">
                            <div className="form__text">
                                {FormDescription}
                            </div>
                            <div className="form__text">
                                <Label className="securedevices-label">
                                    {DetailName_SerialNumber}
                                </Label>
                                <div className="securedevices-input">
                                    {this.props.device.serialNumber}
                                </div>
                            </div>

                            <div className="form__text">
                                <Label className="securedevices-label">
                                    {DetailName_Status}
                                </Label>
                                <div className="securedevices-input">
                                    {this.props.device.deviceStatus}
                                </div>
                            </div>

                            <div className="form__text">
                                <Label className="securedevices-label">
                                    {DetailName_CurrentType}
                                </Label>
                                <div className="securedevices-input">
                                    {ScenarioService.getInstance().GetScenarioFromCache(this.currentDeviceScenarioId)?.name || "None"}
                                </div>
                            </div>

                            <div className="form__text"
                            style={{display: this.props.device.hasVariant ? 'block' : 'none'}}>
                                <Label className="securedevices-label">
                                    {ResourceManager.GetString(ResourceStrings.Common.Current) + " " + this.props.device.variantLabelName}
                                </Label>
                                <div className="securedevices-input">
                                    {this.props.device.variantOptionName}
                                </div>
                            </div>

                            <div className="form__dropdown">
                                <Label
                                    className="securedevices-label"
                                    required
                                    htmlFor={ScenarioElementId}
                            >
                                { DetailName_NewType }
                                </Label>
                                <Dropdown
                                    className="securedevices-dropdown crop-dropdown"
                                    id={ScenarioElementId}
                                    onChange={(event, value) =>
                                        this.HandleDropdownChange(event, value?.key as string)
                                    }
                                    placeholder={ScenarioIdsPlaceholder}
                                    options={this.state.scenarioOptions}
                                    calloutProps={{ doNotLayer: true }}
                                />
                            </div>

                            <div className="form__dropdown"
                                style={{display: this.state.variantOptions.length > 0 ? 'block' : 'none'}}>
                                <Label
                                    className="securedevices-label"
                                    required
                                    htmlFor={VariantElementId}
                            >
                                { ResourceManager.GetString(ResourceStrings.Common.New) + " " + (this.state.variantName || VariantElementId) }
                                </Label>
                                <Dropdown
                                    className="securedevices-dropdown"
                                    id={VariantElementId}
                                    selectedKey={this.state.selectedVariantOption}
                                    onChange={(event, value) =>
                                        this.HandleDropdownChange(event, value?.key as string)
                                    }
                                    placeholder={"Select " + (this.state.variantName || VariantElementId )}
                                    options={this.state.variantOptions}
                                    calloutProps={{ doNotLayer: true }}
                                />
                                <Label className="securedevices-error" style={{ color: "red" }}>
                                    {this.state.formErrors.variant}
                                </Label>
                            </div>
                        </div>
                        <div className="securedevicesform-footer"
                            style={{marginTop: "2rem"}}>
                            <Stack horizontal tokens={stackTokens}>
                                <PrimaryButton
                                    disabled={this.state.isLoading || this.state.apiResponse !== "" || this.state.formInvalid}
                                    onClick={this.HandleSubmit}
                                    text="Submit"
                                />
                                <DefaultButton
                                    disabled={this.state.isLoading || this.state.apiResponse !== ""}
                                    onClick={this.CloseForm}
                                    text="Cancel"
                                />
                                <img
                                    src={Loading}
                                    hidden={!this.state.isLoading}
                                    style={{
                                        width: "35px",
                                        height: "35px",
                                        justifyContent: "center",
                                        display: "flex",
                                        alignItems: "center"
                                    }}
                                    alt="Loading"
                                ></img>
                            </Stack>
                        </div>
                    {this.state.showMessageBar && this.state.apiResponse ? (
                        <div className="securedevicesform-header">
                            {!this.state.error ? (
                                <MessageBar
                                    messageBarType={MessageBarType.success}
                                    isMultiline={true}
                                    onDismiss={() => this.CloseForm()}
                                >
                                    {ReassignScenario_SuccessMessage + this.state.apiResponse}
                                </MessageBar>
                            ) : (
                                <MessageBar
                                    messageBarType={MessageBarType.error}
                                    isMultiline={true}
                                    onDismiss={() => this.CloseMessageBar()}
                                >
                                    {this.state.apiResponse}
                                </MessageBar>
                            )}
                        </div>
                    ) : (
                        <div></div>
                    )}
                    </div>
                </div>
        );
    }
}