import { AuthorizedRole } from "../Auth/Authorization";
import RoleResponseModel from "../Models/RoleResponseModel";
import { ScenarioResponseModel } from "../Models/ScenarioResponseModel";

export class CommonUtils {
    private static instance: CommonUtils;

    public static getInstance = () => {
        if (!CommonUtils.instance) {
            CommonUtils.instance = new CommonUtils();
        }
        return CommonUtils.instance;
    };

    public constructor() {
    }

    public static convertScenarioNameToIdMapping(ScenarioResponseModelOutput: ScenarioResponseModel[]) {
        var scenarioNameToIdMap = new Map();
        ScenarioResponseModelOutput.forEach((option) => {
            scenarioNameToIdMap.set(option.name, option.id);
        });
        return scenarioNameToIdMap;
    }

    public static convertScenarioIdToNameMapping(ScenarioResponseModelOutput: ScenarioResponseModel[]) {
        var scenarioIdToNameMap = new Map();
        ScenarioResponseModelOutput.forEach((option) => {
            scenarioIdToNameMap.set(option.id, option.name);
        });
        return scenarioIdToNameMap;
    }

    public static convertRoleNameToIdMapping(RoleResponseModelOutput: RoleResponseModel[]) {
        var roleIdToNameMap = new Map();
        RoleResponseModelOutput.forEach((option) => {
            roleIdToNameMap.set(option.name, option.id);
        });
        return roleIdToNameMap;
    }

    public static MapIdListByValues(nameToIdMap: Map<string, string>, keys: string[]) {
        var values = [];
        if (keys?.length > 0) {
            for (const key of keys) {
                const value = nameToIdMap.get(key);
                if (value !== undefined) {
                    values.push(value);
                }
            }
        }
        return values;
    }

    public static convertMetroData(metroData: [{ key: number, value: string }]) {
        var values = [];
        for (var data in metroData) {
            values.push(data);
        }
        return values;
    }

    public static GetRoleData = (): RoleResponseModel[] => {
        var RoleResponseModelOutput: RoleResponseModel[] = [];
        RoleResponseModelOutput.push(new RoleResponseModel(AuthorizedRole.OrganizationAdministrator, "Organization Administrator"));
        RoleResponseModelOutput.push(new RoleResponseModel(AuthorizedRole.ScenarioOwner, "Scenario Owner"));
        RoleResponseModelOutput.push(new RoleResponseModel(AuthorizedRole.ScenarioChamp, "Scenario Champ"));
        RoleResponseModelOutput.push(new RoleResponseModel(AuthorizedRole.ScenarioUser, "Scenario User"));

        return RoleResponseModelOutput;
    };

    public static GetRoleDataScenarioOwnerView = (): RoleResponseModel[] => {
        var RoleResponseModelOutput: RoleResponseModel[] = [];
        RoleResponseModelOutput.push(new RoleResponseModel(AuthorizedRole.ScenarioChamp, "Scenario Champ"));
        RoleResponseModelOutput.push(new RoleResponseModel(AuthorizedRole.ScenarioUser, "Scenario User"));

        return RoleResponseModelOutput;
    };
}