import React, { Component } from "react";
import * as Portal from "Components/Framework/Page/CommonPage";
import {
    Label,
    IDropdownOption,
    TextField,
    Dropdown,
    PrimaryButton,
    DefaultButton,
    IStackTokens,
    Stack
} from '@fluentui/react';
import {
    MessageBar,
    MessageBarType
} from "office-ui-fabric-react";
import * as Common from "Components/Common/ValyrianCommonComponents";
import { Authorization, AuthorizedRole } from "Auth/Authorization";
import { DeviceOnboardingService } from "Services/DeviceOnboardingService";
import { ScenarioService } from 'Services/ScenarioService';
import { ScenarioVariant, Scenario, ScenarioVariantType } from "../../../Models/ScenarioResponseModel";
import Loading from "Images/Loading.gif";
import "../../../scss/common.css";


const Component_Name = "Onboard Device";
const UnAuthorized = Common.ResourceManager.GetString(Common.ResourceStrings.DeviceOnboarding.UnAuthorized);
let AlertMessage = Common.ResourceManager.GetString(Common.ResourceStrings.DeviceOnboarding.UnAuthorized);
// Parameter declaration
const SerialNumber = Common.ResourceManager.GetString(Common.ResourceStrings.Device.SerialNumber);
const AssetId = Common.ResourceManager.GetString(Common.ResourceStrings.Device.AssetId);
const Facility = Common.ResourceManager.GetString(Common.ResourceStrings.Locations.Facility);
const Room = Common.ResourceManager.GetString(Common.ResourceStrings.Locations.Room);
const Locker = Common.ResourceManager.GetString(Common.ResourceStrings.Locations.Locker);
const Slot = Common.ResourceManager.GetString(Common.ResourceStrings.Locations.Slot);
const ScenarioIds = Common.ResourceManager.GetString(Common.ResourceStrings.Device.Scenario);
const Variant = Common.ResourceManager.GetString(Common.ResourceStrings.Scenarios.Variant);
// Placeholder declaration
const SerialNumberPlaceholder = Common.ResourceManager.GetString(Common.ResourceStrings.Device.SerialNumberPlaceholder);
const AssetIdPlaceholder = Common.ResourceManager.GetString(Common.ResourceStrings.Device.AssetIdPlaceholder);
const FacilityPlaceholder = Common.ResourceManager.GetString(Common.ResourceStrings.Locations.FacilityPlaceholder);
const RoomPlaceholder = Common.ResourceManager.GetString(Common.ResourceStrings.Locations.RoomPlaceholder);
const LockerPlaceholder = Common.ResourceManager.GetString(Common.ResourceStrings.Locations.LockerPlaceholder);
const SlotPlaceholder = Common.ResourceManager.GetString(Common.ResourceStrings.Locations.SlotPlaceholder);
const ScenarioIdsPlaceholder = Common.ResourceManager.GetString(Common.ResourceStrings.Device.ScenarioIdsPlaceholder);
// Invalid messages declaration
const SerialNumberInvalidMessage = Common.ResourceManager.GetString(Common.ResourceStrings.Device.SerialNumberInvalidMessage);
const AssetIdInvalidMessage = Common.ResourceManager.GetString(Common.ResourceStrings.Device.AssetIdInvalidMessage);
const FacilityInvalidMessage = Common.ResourceManager.GetString(Common.ResourceStrings.Locations.FacilityInvalidMessage);
const RoomInvalidMessage = Common.ResourceManager.GetString(Common.ResourceStrings.Locations.RoomInvalidMessage);
const LockerInvalidMessage = Common.ResourceManager.GetString(Common.ResourceStrings.Locations.LockerInvalidMessage);
const SlotInvalidMessage = Common.ResourceManager.GetString(Common.ResourceStrings.Locations.SlotInvalidMessage);
const ScenarioIdsInvalidMessage = Common.ResourceManager.GetString(Common.ResourceStrings.Device.ScenarioIdsInvalidMessage);
// Element Ids
const ScenarioElementId = "ScenarioIds";
const VariantElementId = "Variant";
// Length Constants
const TextLengthZero = 0;
const TextLengthOne = 1;
const TextLengthThree = 3;
const TextLengthSix = 6;
const TextLengthfifteen = 15;

const ScenarioOptions: IDropdownOption[] = [];

// Used for calling the service.tsx file for device onboarding which contains the API.
let deviceOnboardingService = DeviceOnboardingService.getInstance();
let TracingService = Common.TracingService.getInstance();
const stackTokens: IStackTokens = { childrenGap: 15 };
var scenarios: Scenario[];

export class DeviceOnboarding extends Component<any, any> implements Portal.ICommonPage {
    // Required member for a page
    public pageProps: Portal.CommonPageProps = {
        authRequired: true,
        pageTitle: "Device Onboarding",
        authorizedRoles: [
            AuthorizedRole.OrganizationAdministrator,
            AuthorizedRole.CapacityManager,
            AuthorizedRole.ScenarioManager,
            AuthorizedRole.ScenarioOwner
        ]
    };

    constructor(props: any) {
        super(props);
        //Refactor to use device object here and user Device.SerialNumber etc etc and use that device object to submit the request
        this.state = {
            SerialNumber: "",
            AssetId: "",
            Facility: "",
            Room: "",
            Locker: "",
            Slot: "",
            ScenarioIds: "",
            TagKeys: "",
            formErrors: {
                SerialNumber: "",
                AssetId: "",
                Facility: "",
                Room: "",
                Locker: "",
                Slot: "",
                ScenarioIds: "",
                Variant: ""
            },
            deviceApiCall: false,
            showMessageBar: true,
            isDeviceOnboarded: false,
            isValidUser: false,
            errorMessage: "",
            errorCode: "",
            isLoading: false,
            displayVariant: false,
            scenarioVariant: ScenarioVariant.NullVariant,
            variantOptions: new Array<{key: string, text: string}>()
        };

        

        this.validateField = this.validateField.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.handleDeviceOnboard = this.handleDeviceOnboard.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.closeMessageBar = this.closeMessageBar.bind(this);
        this.deviceOnboardSuccessMessage = this.deviceOnboardSuccessMessage.bind(this);
        this.fillScenarioDropdownOptions = this.fillScenarioDropdownOptions.bind(this);
        this.UserFriendlyErrorMessageMapping = this.UserFriendlyErrorMessageMapping.bind(this);

    }

    private Constant = Common.Constants.getInstance();

    validateField(fieldName: any, value: any) {
        let fieldValidationErrors = this.state.formErrors;

        switch (fieldName) {
            case SerialNumber:
                let SerialNumberValid = value.length >= TextLengthOne && value.length <= TextLengthfifteen;
                fieldValidationErrors.SerialNumber = SerialNumberValid
                    ? ""
                    : SerialNumberInvalidMessage;
                break;
            case AssetId:
                let AssetIdValid = value.length >= TextLengthSix && value.length <= TextLengthfifteen;
                fieldValidationErrors.AssetId = AssetIdValid
                    ? ""
                    : AssetIdInvalidMessage;
                break;
            case Facility:
                let FacilityValid = value.length >= TextLengthThree && value.length <= TextLengthSix;
                fieldValidationErrors.Facility = FacilityValid
                    ? ""
                    : FacilityInvalidMessage;
                break;
            case Room:
                let RoomValid = value.length >= TextLengthOne && value.length <= TextLengthfifteen;
                fieldValidationErrors.Room = RoomValid
                    ? ""
                    : RoomInvalidMessage;
                break;
            case Locker:
                let LockerValid = value.length >= TextLengthOne && value.length <= TextLengthfifteen;
                fieldValidationErrors.Locker = LockerValid
                    ? ""
                    : LockerInvalidMessage;
                break;
            case Slot:
                let SlotValid = value.length >= TextLengthOne && value.length <= TextLengthfifteen;
                fieldValidationErrors.Slot = SlotValid
                    ? ""
                    : SlotInvalidMessage;
                break;
            case ScenarioIds:
                let ScenarioIdsValid = value.length > TextLengthZero;
                fieldValidationErrors.ScenarioIds = ScenarioIdsValid
                    ? ""
                    : ScenarioIdsInvalidMessage;
                break;
            case Variant:
                let VariantValid = value.length > TextLengthZero;
                fieldValidationErrors.Variant = VariantValid
                    ? ""
                    : Common.Helper.stringFormat(
                        Common.ResourceStrings.DeviceOnboarding.InvalidScenarioVariantMessage,
                        this.state.scenarioVariant.name
                    );
                break;
            default:
                break;
        }
        this.setState({ formErrors: fieldValidationErrors });
    }

    closeMessageBar = () => {
        this.setState({
            SerialNumber: "",
            AssetId: "",
            Facility: "",
            Room: "",
            Locker: "",
            Slot: "",
            ScenarioIds: "",
            TagKeys: "",
            deviceApiCall: false,
            showMessageBar: false,
            isDeviceOnboarded: false,
            errorMessage: "",
            errorCode: "",
            isValidUser: true,
            displayVariant: false,
            scenarioVariant: ScenarioVariant.NullVariant
        });
    };

    fillScenarioDropdownOptions = async () => {
        const user = this.context;
        scenarios = (await ScenarioService.getInstance().GetScenariosAsync(user.IdToken));
        scenarios.sort((a, b) => a.name < b.name ? -1 : 1).forEach((option) => {
            ScenarioOptions.push({ key: option.id, text: option.name });
        });
    };

    handleInputChange = (
        event: React.FormEvent<
            HTMLInputElement | HTMLTextAreaElement | HTMLDivElement
        >,
        newValue: string | undefined
    ): void => {
        const target = event.target as HTMLInputElement;
        const newAsset = { [target.id]: target.value };
        this.setState(newAsset);
    };

    handleDropdownChange = (
        event: React.FormEvent<
            HTMLInputElement | HTMLDivElement | HTMLTextAreaElement
        >,
        value: string | undefined
    ): void => {
        const target = event.target as HTMLInputElement;

        if (target.id === ScenarioElementId) {
            this.handleScenarioChange(value);
            return;
        }
        else if (target.id === VariantElementId) {
            this.handleVariantChange(value);
            return;
        }
        
        const newResource = { [target.id]: value };
        this.setState(newResource);
        
    };

    handleScenarioChange = (scenarioId: string | undefined) => {
        const scenario = scenarios.filter(s => s.id === scenarioId)[0];
        const variants = scenario.GetVariants(ScenarioVariantType.Device);
        if (variants.length > 0) {
            var variant = variants[0];

            const newState = {
                [ScenarioElementId]: scenarioId,
                displayVariant: true,
                scenarioVariant: variant,
                variantOptions: variant.GetOptions().sort((a, b) => a.text < b.text ? -1 : 1),
                TagKeys: ""
            };
            this.setState(newState);
            return;
        }

        const newState = { [ScenarioElementId]: scenarioId, displayVariant: false, scenarioVariant: null, TagKeys: "" };
        this.setState(newState);
    };

    handleVariantChange = (optionId: string | undefined) => {
        const newState = {
            TagKeys: optionId
        };
        this.setState(newState);
    };

    handleDeviceOnboard = async () => {
        this.validateField(SerialNumber, this.state.SerialNumber);
        this.validateField(AssetId, this.state.AssetId);
        this.validateField(Facility, this.state.Facility);
        this.validateField(Room, this.state.Room);
        this.validateField(Locker, this.state.Locker);
        this.validateField(Slot, this.state.Slot);
        this.validateField(ScenarioIds, this.state.ScenarioIds);
        if (this.state.displayVariant === true) {
            this.validateField(Variant, this.state.TagKeys);
        }
        if (
            this.state.formErrors.SerialNumber === "" &&
            this.state.formErrors.AssetId === "" &&
            this.state.formErrors.Facility === "" &&
            this.state.formErrors.Room === "" &&
            this.state.formErrors.Locker === "" &&
            this.state.formErrors.Slot === "" &&
            this.state.formErrors.ScenarioIds === "" &&
            this.state.formErrors.Variant === ""
        ) {
            await this.deviceOnboarding().then((results) => {
                this.setState({ deviceApiCall: true });
                console.log("submitting these values : " + this.state);
            });
        }
    };

    handleClear = () => {
        this.setState({
            SerialNumber: "",
            AssetId: "",
            Facility: "",
            Room: "",
            Locker: "",
            Slot: "",
            ScenarioIds: "",
            Tags: "",
            formErrors: {
                SerialNumber: "",
                AssetId: "",
                Facility: "",
                Room: "",
                Locker: "",
                Slot: "",
                ScenarioIds: ""
            },
            deviceApiCall: false,
            isDeviceOnboarded: false,
            isValidUser: true,
            notification:"",
            errorMessage: "",
            errorCode: "",
            showMessageBar: true,
            displayVariant: false
        });
        
        console.log("clear");

    };

    deviceOnboardSuccessMessage = () => {
        console.log(
            Common.Helper.stringFormat(
                Common.ResourceStrings.DeviceOnboarding.DeviceOnboardSuccessMessage
            )
        );
        return Common.ResourceManager.GetString(
            Common.Helper.stringFormat(
                Common.ResourceStrings.DeviceOnboarding.DeviceOnboardSuccessMessage, this.state.notification
            )
        );
    };

    UserFriendlyErrorMessageMapping = () => {
        switch (this.state.errorCode) {
            case "InternalServerError":
                return Common.ResourceManager.GetString(
                    Common.Helper.stringFormat(
                        Common.ResourceStrings.DeviceOnboarding
                            .DeviceOnboardFailedInternalServerErrorMessage,
                        this.Constant.getSupportEmail
                    )
                );
            case "Conflict":
                return Common.ResourceManager.GetString(
                    Common.Helper.stringFormat(
                        Common.ResourceStrings.DeviceOnboarding
                            .DeviceOnboardFailedSNoExistsMessage,
                        this.state.errorMessage
                    )
                );
            case "BadRequest":
                if (
                    this.state.errorMessage.includes("is not found in Autopilot")
                ) {
                    return Common.ResourceManager.GetString(
                        Common.Helper.stringFormat(
                            Common.ResourceStrings.DeviceOnboarding
                                .DeviceOnboardFailedNotInAutopilotMessage,
                            this.state.errorMessage
                        )
                    );
                }

                if (
                    this.state.errorMessage.includes("Device with the serial number")
                ) {
                    return Common.ResourceManager.GetString(
                        Common.Helper.stringFormat(
                            Common.ResourceStrings.DeviceOnboarding
                                .DeviceOnboardFailedSNoExistsMessage,
                            this.state.errorMessage
                        )
                    );
                }

                if (
                    this.state.errorMessage.includes("Facility code "+ this.state.Facility + " is not found")
                ) {
                    return Common.ResourceManager.GetString(
                        Common.Helper.stringFormat(
                            Common.ResourceStrings.DeviceOnboarding
                                .DeviceOnboardFailedFacilityNotfoundMessage,
                            this.state.errorMessage
                        )
                    );
                }

                else {
                    return Common.ResourceManager.GetString(
                        Common.Helper.stringFormat(
                            Common.ResourceStrings.DeviceOnboarding
                                .DeviceOnboardFailedBadRequestGenericMessage,
                            this.state.errorMessage
                        )
                    );
                }
            //If the error is not handled above then we display actual error message from API
            //to ensure UI never breaks even if error strings are modified on API end.
            default:
                return Common.ResourceManager.GetString(
                    Common.Helper.stringFormat(
                        Common.ResourceStrings.DeviceOnboarding
                            .DeviceOnboardFailedGenericMessage,
                        this.state.errorMessage
                    )
                );
        }
    };

    static contextType = Common.UserContext;

    private userWithDeviceManagerRole = (user: {
        displayName: string;
        userPrincipalName: string;
        userObjectID: string;
        roles: string[];
        IdToken: string;
        TenantId: string;
    }) => {
        return user.IdToken && Authorization.AuthorizeUser(user.roles, [AuthorizedRole.OrganizationAdministrator, AuthorizedRole.DeviceManager]);
    };

    private isDevicemanager = () => {
        const user = this.context;
        
        if (this.userWithDeviceManagerRole(user)) {
            this.setState({
                isValidUser: true
            });
        } else {
            TracingService.trace(Component_Name, "Not authorized as Device Manager");
            AlertMessage = UnAuthorized;
            this.setState({
                isValidUser: false
            });
        }
    };

    private deviceOnboarding = async () => {
        const user = this.context;
        if (this.userWithDeviceManagerRole(user)) {
            this.setState({ isLoading: true });
            TracingService.trace(
                Component_Name,
                "Authorized Device Manager, now onboarding new device."
            );
            await deviceOnboardingService
                .DeviceOnboarding(
                    user.IdToken,
                    user.userPrincipalName,
                    this.state.Facility,
                    this.state.Room,
                    this.state.Locker,
                    this.state.Slot,
                    this.state.SerialNumber,
                    this.state.AssetId,
                    this.state.ScenarioIds,
                    this.state.TagKeys !== "" ? [this.state.TagKeys] : []
                )
                .then((results) => {
                    console.log(results);
                    if (results.error) {
                        this.setState({
                            deviceApiCall: true,
                            showMessageBar: true,
                            isDeviceOnboarded: false,
                            errorMessage: results.error.message,
                            errorCode: results.error.code,
                            isLoading: false
                        });
                    }
                    else{
                        this.setState({
                            SerialNumber: "",
                            AssetId: "",
                            Facility: "",
                            Room: "",
                            Locker: "",
                            Slot: "",
                            ScenarioIds: "",
                            TagKeys: "",
                            deviceApiCall: true,
                            showMessageBar: true,
                            isDeviceOnboarded: true,
                            notification: results.responseMessage,
                            errorMessage: "",
                            errorCode: "",
                            isLoading: false
                        });
                    }
                });
        } else {
            TracingService.trace(Component_Name, "Not authorized as Device Manager");
            AlertMessage = UnAuthorized;
            this.setState({
                isDeviceOnboarded: false,
                errorMessage: "Not authorized to onboard a device."
            });
        }
    };


    async componentDidMount() {
        this.isDevicemanager();
        this.fillScenarioDropdownOptions();
    }

    render() {
        return (
            <Portal.CommonPage {...this.pageProps}>
            <div className="wrapper">
                <div className="securedevicesform" hidden={!this.state.isValidUser}>
                    <Label className="securedevicesform-title">{this.pageProps.pageTitle}</Label>
                    <div className="securedevicesform-body">
                        <div className="form__text">
                            <Label
                                className="securedevices-label"
                                required
                                htmlFor="SerialNumber"
                            >
                                Serial Number
                            </Label>
                            <TextField
                                className="securedevices-input"
                                id="SerialNumber"
                                value={this.state.SerialNumber}
                                placeholder={SerialNumberPlaceholder}
                                onChange={(event, value) =>
                                    this.handleInputChange(event, value)
                                }
                            />
                            <Label className="securedevices-error" style={{ color: "red" }}>
                                {this.state.formErrors.SerialNumber}
                            </Label>
                        </div>


                        <div className="form__text">
                            <Label
                                className="securedevices-label"
                                required
                                htmlFor="AssetId"
                            >
                                Asset Id
                            </Label>
                            <TextField
                                className="securedevices-input"
                                id="AssetId"
                                value={this.state.AssetId}
                                placeholder={AssetIdPlaceholder}
                                onChange={(event, value) =>
                                    this.handleInputChange(event, value)
                                }
                            />
                            <Label className="securedevices-error" style={{ color: "red" }}>
                                {this.state.formErrors.AssetId}
                            </Label>
                        </div>


                        <div className="form__text">
                            <Label
                                className="securedevices-label"
                                required
                                htmlFor="Facility"
                            >
                                Facility Short Code
                            </Label>
                            <TextField
                                className="securedevices-input"
                                id="Facility"
                                value={this.state.Facility}
                                placeholder={FacilityPlaceholder}
                                onChange={(event, value) =>
                                    this.handleInputChange(event, value)
                                }
                            />
                            <Label className="securedevices-error" style={{ color: "red" }}>
                                {this.state.formErrors.Facility}
                            </Label>
                        </div>


                        <div className="form__text">
                            <Label
                                className="securedevices-label"
                                required
                                htmlFor="Room"
                            >
                                Room
                            </Label>
                            <TextField
                                className="securedevices-input"
                                id="Room"
                                value={this.state.Room}
                                placeholder={RoomPlaceholder}
                                onChange={(event, value) =>
                                    this.handleInputChange(event, value)
                                }
                            />
                            <Label className="securedevices-error" style={{ color: "red" }}>
                                {this.state.formErrors.Room}
                            </Label>
                        </div>


                        <div className="form__text">
                            <Label
                                className="securedevices-label"
                                required
                                htmlFor="Locker"
                            >
                                Locker
                            </Label>
                            <TextField
                                className="securedevices-input"
                                id="Locker"
                                value={this.state.Locker}
                                placeholder={LockerPlaceholder}
                                onChange={(event, value) =>
                                    this.handleInputChange(event, value)
                                }
                            />
                            <Label className="securedevices-error" style={{ color: "red" }}>
                                {this.state.formErrors.Locker}
                            </Label>
                        </div>


                        <div className="form__text">
                            <Label
                                className="securedevices-label"
                                required
                                htmlFor="Slot"
                            >
                                Slot
                            </Label>
                            <TextField
                                className="securedevices-input"
                                id="Slot"
                                value={this.state.Slot}
                                placeholder={SlotPlaceholder}
                                onChange={(event, value) =>
                                    this.handleInputChange(event, value)
                                }
                            />
                            <Label className="securedevices-error" style={{ color: "red" }}>
                                {this.state.formErrors.Slot}
                            </Label>
                        </div>


                        <div className="form__dropdown">
                            <Label
                                className="securedevices-label"
                                required
                                htmlFor={ScenarioElementId}
                            >
                                Scenario
                            </Label>
                            <Dropdown
                                className="securedevices-dropdown crop-dropdown"
                                id={ScenarioElementId}
                                selectedKey={this.state.ScenarioIds}
                                onChange={(event, value) =>
                                    this.handleDropdownChange(event, value?.key as string)
                                }
                                placeholder={ScenarioIdsPlaceholder}
                                options={ScenarioOptions}
                                calloutProps={{ doNotLayer: true }}
                            />
                            <Label className="securedevices-error" style={{ color: "red" }}>
                                {this.state.formErrors.ScenarioIds}
                            </Label>
                        </div>
                        <div className="form__dropdown" style={{display: this.state.displayVariant ? 'block' : 'none'}}>
                            <Label
                                className="securedevices-label"
                                required
                                htmlFor={VariantElementId}
                            >
                                {(this.state.scenarioVariant !== undefined && this.state.scenarioVariant !== null) 
                                ? this.state.scenarioVariant.name : "Variant"}
                            </Label>
                            <Dropdown
                                className="securedevices-dropdown crop-dropdown"
                                id={VariantElementId}
                                selectedKey={this.state.TagKeys}
                                onChange={(event, value) =>
                                    this.handleDropdownChange(event, value?.key as string)
                                }
                                placeholder={"Select " + ((this.state.scenarioVariant !== undefined && this.state.scenarioVariant !== null) 
                                    ? this.state.scenarioVariant.name : "Variant")}
                                options={this.state.variantOptions}
                                calloutProps={{ doNotLayer: true }}
                            />
                            <Label className="securedevices-error" style={{ color: "red" }}>
                                {this.state.formErrors.Variant}
                            </Label>
                        </div>
                    </div>
                    <div className="securedevicesform-footer">
                        <Stack horizontal tokens={stackTokens}>
                            <PrimaryButton
                                disabled={this.state.isLoading}
                                onClick={this.handleDeviceOnboard}
                                text="Submit"
                            />
                            <DefaultButton
                                disabled={this.state.isLoading}
                                onClick={this.handleClear}
                                text="Clear"
                            />
                            <img
                                src={Loading}
                                hidden={!this.state.isLoading}
                                style={{
                                    width: "35px",
                                    height: "35px",
                                    justifyContent: "center",
                                    display: "flex",
                                    alignItems: "center"
                                }}
                                alt="Loading"
                            ></img>
                        </Stack>
                    </div>
                    {this.state.showMessageBar && this.state.deviceApiCall ? (
                        <div className="securedevicesform-header">
                            {this.state.isDeviceOnboarded ? (
                                <MessageBar
                                    messageBarType={MessageBarType.success}
                                    isMultiline={true}
                                    onDismiss={() => this.closeMessageBar()}
                                >
                                    {this.deviceOnboardSuccessMessage()}
                                </MessageBar>
                            ) : (
                                <MessageBar
                                    messageBarType={MessageBarType.error}
                                    isMultiline={true}
                                    onDismiss={() => this.closeMessageBar()}
                                >
                                    {this.UserFriendlyErrorMessageMapping()}
                                </MessageBar>
                            )}
                        </div>
                    ) : (
                        <div></div>
                    )}
                </div>
            </div>
            </Portal.CommonPage>
        );
    }
}