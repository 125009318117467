import * as React from 'react';
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { IDetailsListProps, DetailsList } from 'office-ui-fabric-react/lib/DetailsList';

import { ILocationFilterProps } from 'Components/Framework/List/LocationFilters';
import { LocationFilters } from 'Components/Framework/List/LocationFilters';
import 'scss/colors.css';
import 'scss/modules.css';


export interface IFilterableDetailsListProps {
    listProps: IDetailsListProps;
    filterProps?: ILocationFilterProps;
}

export class FilterableDetailsList extends React.Component<IFilterableDetailsListProps> {

    public render(): JSX.Element {
        return (
            <Fabric>
                <div className='filterable-details-list-container'>
                    {
                        (this.props.filterProps) ?
                            <LocationFilters toCallBack={this.props.filterProps.toCallBack}></LocationFilters>
                            :
                            <br/>
                    }
                    <div className='filterable-details-list-header dark' />
                    <div style={{ position: 'relative', width: '100%' }}>
                        <DetailsList { ...this.props.listProps } />
                    </div>
                    <div className='filterable-details-list-footer dark' />
                </div>
            </Fabric>
        );
    }

}