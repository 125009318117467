import * as React from "react";
import {
    DataGridBody,
    DataGridRow,
    DataGrid,
    DataGridHeader,
    DataGridHeaderCell,
    DataGridCell,
    Spinner,
    TableCellLayout,
    FluentProvider,
    DataGridProps
} from "@fluentui/react-components";
import { CommandBar, ICommandBarItemProps } from '@fluentui/react';
import { CommonListFilterBar, ICommonListFiltersProps } from "./Filters/CommonListFilters";
import { IListPagingProps, ControlledListPagingNavigator } from "./ListPaging";
import { Type } from "typescript";
import { frameworkLightTheme } from "../Theme/Theme";
import type { CommonListSearchProps } from "./Filters/Search";
import "./CommonList.css";
import { ConsoleLogger } from "Services/Logger";

export const defaultListPageSize: number = 20;

export function renderStandardCell(content: any, tooltipText?: string): React.JSX.Element {
    return <TableCellLayout title={tooltipText} truncate>{content}</TableCellLayout>;
};

export class CommonListCache {
    private items: any[];
    private isDirty: boolean;

    constructor() {
        this.items = [];
        this.isDirty = false;
    };

    public Append(items: any[]) {
        this.items = this.items.concat(items);
    };

    public Read = () => {
        return this.items;
    };

    public EditItem(cache: any[]) {
        // edit a spefiic item
        this.items = cache;
        this.isDirty = true;
    };

    public Clear() {
        this.items = [];
        this.isDirty = false;
    };

    public IsDirty() {
        return this.isDirty;
    };
};


interface ICommonListState {
    // List can control loading state
    isLoading: boolean;

    // Items displayed in the list. Display items will change when page changes, reading only a slice of the cache.
    displayItems: any;

    // List is not always displaying items from the cache. On first load the displayed items will come from the props.items.
    usingCache: boolean;

    // This value is set when the paging navigator component registers a click
    currentPage: number;
};

export interface CommonListProps {
    dataGridProps: DataGridProps;
    itemCountName: string;
    isLoading: boolean;
    isRefreshing: boolean;
    onRefresh: () => any;
    filterProps?: ICommonListFiltersProps;
    pagingProps?: IListPagingProps;
    searchProps?: CommonListSearchProps;
    clearCache?: boolean;
    refreshToken?: number;
};

export class CommonList extends React.Component<CommonListProps, ICommonListState> {
    // Internal controlled cache.
    private _cache: Type[];

    // Indicates whether to use internal controlled cache or external uncontrolled cache.
    private _isCacheControlled: boolean;

    // Indicates whether to use the cache or the items directly from props.
    private _usingCache = false;

    // The number of items to display per page. Determined by default or paging props. Page size will not change after this component is mounted.
    private _pageSize = defaultListPageSize;

    private _refreshToken: number = 0;

    constructor(props: CommonListProps) {
        super(props);
        this._cache = this.props.dataGridProps.items;
        this._isCacheControlled = this.props.pagingProps?.getCache === undefined ? true : false;
        this._pageSize = this.props.pagingProps ? this.props.pagingProps.pageSize : defaultListPageSize;
        this.state = {
            isLoading: false,
            displayItems: this.props.dataGridProps.items,
            usingCache: false,
            currentPage: 1
        };
    };

    /**
     * Get the command buttons to be added to the CommandBar.
     * @returns Array of ICommandBarItemProps
     */
    private commandBarItems = () => {
        let items = new Array<ICommandBarItemProps>();

        items.push(
            {
                key: 'refresh',
                text: "Refresh",
                iconProps: { iconName: 'Refresh' },
                onClick: () => {
                    this._cache = [];
                    this._usingCache = false;
                    this.props.onRefresh();
                },
                disabled: false
            }
        );
        return items;
    };

    /**
     * Get the items for a specific page from a list.
     * @param page page to retrieve (non-zero based index)
     * @param list array to retrive the page from
     * @returns Array of items from the list according to page size and current page
     */
    private getCachedItemsForPage(page: number, list: any[]): any[] {
        const totalItems = this.props.pagingProps?.totalCount || 0;
        const lastItemIndexInPage = (page-1) * this._pageSize + this._pageSize;
        return list.slice((page-1) * this._pageSize, totalItems > lastItemIndexInPage ? lastItemIndexInPage : totalItems);
    }

    /**
     * Artificial half-second delay. Used when fetching from cache, gives user a clear indication that work was done.
     */
    private async delayFetchFromCache() {
        await new Promise(resolve => setTimeout(resolve, 500));
    };

    /**
     * Fetch previous page items from the cache and update items displayed in the list. Use artificial loading delay.
     * @param page number of the page to fetch (non-zero based)
     */
    private FetchPreviousPage = (page: number) => {
        this.setState({isLoading: true, usingCache: true, currentPage: page});
        this.delayFetchFromCache();
        this.setState({
            isLoading: false,
            displayItems: this.getCachedItemsForPage(page, this.props.pagingProps?.getCache ? this.props.pagingProps.getCache() : this._cache)
        });
    };

    /**
     * Fetch next page items from the uncontrolled cache (externally managed) and update items displayed in the list.
     * If the next page cannot be fulfilled with current items in the cache, request the cache be updated before fetching items from it.
     * @param page number of the page to fetch (non-zero based)
     */
    private fetchNextPageFromUncontrolledCache = async(page: number) => {
        const cache = this.props.pagingProps?.getCache;
        console.log("fetching page " + page);

        if (cache) {
            if ((cache().length < this._pageSize * page) && this.props.pagingProps?.onFetchNextBatch) {
                // Cache does not have enough items to display the page, request the cache controller to update the cache

                if (this.props.pagingProps?.onFetchNextBatch) {
                    await this.props.pagingProps.onFetchNextBatch().then(res => {
                        this.setState({
                            isLoading: false,
                            displayItems: this.props.pagingProps?.getCache ? this.getCachedItemsForPage(page, this.props.pagingProps.getCache()) : []
                        });
                    });
                }
                else {
                    // log error. no way to fetch more items.
                }
            }
            else {
                // Cache has enough items to display the page
                this.delayFetchFromCache();
                this.setState({
                    displayItems: this.getCachedItemsForPage(page, cache()),
                    isLoading: false
                });

                // The following code will be executed in the asynchronously as the list has already begun to re-render when we updated state above.

                // Look ahead caching: request the cache controller to update the cache in the background 
                if ((cache().length < (this.props.pagingProps?.totalCount || 0)) && (cache().length < this._pageSize * (page+2)) && this.props.pagingProps?.onFetchNextBatch) {
                    await this.props.pagingProps.onFetchNextBatch();
                }
            }
        }
    };

    /**
     * Fetch the next page items from the controlled cache (internally managed by this component).
     * The controlled cache is full by design because it never needs to request more data from a parent.
     * @param page number of the page to fetch (non-zero based)
     */
    private getNextPageFromControlledCache(page: number) {
        this.delayFetchFromCache();
        this.setState({
            isLoading: false,
            displayItems: this.getCachedItemsForPage(page, this._cache)
        });
    };

    /**
     * Fetch the next page items.
     * @param page number of the page to fetch (non-zero based)
     */
    private FetchNextPage = (page: number) => {
        this._usingCache = true;
        this.setState({isLoading: true, usingCache: true, currentPage: page});
        
        if (this._isCacheControlled) {
            // Controlled cache is managed internally by the Standard List
            // Controlled caches are complete (full) by design
            console.log("internal cache fetch");
            this.getNextPageFromControlledCache(page);            
        }
        else {
            // Uncontrolled cache is managed externally via parent props
            // Cache completion is not guaranteed
            console.log("external cache fetch");
            this.fetchNextPageFromUncontrolledCache(page);
        }
    };

    render() {
        // Determine whether to display the filter & search bar
        let displayFilterBar = false;
        if (this.props.searchProps !== undefined || this.props.filterProps !== undefined) {
            displayFilterBar = true;
        }

        // Reset the list state if needed.
        let reset = false;

        if (this.props.refreshToken) {
            if (this.props.refreshToken !== this._refreshToken) {
                this._refreshToken = this.props.refreshToken;
                this._usingCache = false;
                reset = true;
            }
        }
        
        if (this.props.isRefreshing === true || this.props.dataGridProps.items.length === 0 || reset) {
            this._usingCache = false;
        }

        // Intercept the props that were passed for the DataGrid.
        // Update the props for the DataGrid to display page appropriate items
        let gridProps = this.props.dataGridProps;

        if (this.props.pagingProps === undefined) {
            // Paging is NOT configured. Pass the items as-is to display all items.
            gridProps.items = this.props.dataGridProps.items;
        }
        else {
            // Paging IS configured

            if (this.state.usingCache && this._usingCache) {
                // Display the cached items for the current page
                gridProps.items = this.state.displayItems;
            }
            else {
                // Display the items being passed

                if (this.props.dataGridProps.items.length <= this._pageSize) {
                    // Total items can fit one page. Pass the items as-is to display all items.
                    gridProps.items = this.props.dataGridProps.items;
                    reset = true;
                }
                else {
                    // Cache the items and display the first page of items being passed.
                    this._cache = this.props.dataGridProps.items;
                    gridProps.items = this.props.dataGridProps.items.slice(0, this._pageSize);
                    reset = true;
                }
            }
        }
        
        gridProps.focusMode = 'composite';

        let itemCountLabel = (this.props.pagingProps ? this.props.pagingProps.totalCount : this.props.dataGridProps.items.length) + " " + this.props.itemCountName;

        return (
            <FluentProvider theme={frameworkLightTheme}>
                <CommandBar items={this.commandBarItems()} className="command-bar" />
                {displayFilterBar ? <CommonListFilterBar {... { searchProps: this.props.searchProps, filterProps: this.props.filterProps }} ></CommonListFilterBar> : null}
                <p className="standard-list-item-count">{itemCountLabel}</p>
                <div className="standard-list-datagrid-container">
                    <div className="standard-list-loading-overlay" style={{ display: this.props.isLoading || this.state.isLoading ? 'block' : 'none' }}>
                        <Spinner style={{ marginTop: '5rem' }} size="huge" labelPosition="below" label={"Loading " + this.props.itemCountName} />
                    </div>
                    <DataGrid {...gridProps}>
                        <DataGridHeader>
                            <DataGridRow>
                                {({ renderHeaderCell }) => (
                                    <DataGridHeaderCell><span className="standard-list-datagrid-header">{renderHeaderCell()}</span></DataGridHeaderCell>
                                )}
                            </DataGridRow>
                        </DataGridHeader>
                        <DataGridBody<Type>>
                            {({ item, rowId }) => (
                                <DataGridRow<Type> key={rowId}>
                                    {({ renderCell }) => (
                                        <DataGridCell>{renderCell(item)}</DataGridCell>
                                    )}
                                </DataGridRow>
                            )}
                        </DataGridBody>
                    </DataGrid>
                </div>
                {this.props.pagingProps && this.props.dataGridProps.items.length > 0 ?
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <ControlledListPagingNavigator {... {
                            currentPage: reset ? 1 : this.state.currentPage,
                            disabled: this.state.isLoading,
                            pageCount: Math.floor((this.props.pagingProps?.totalCount || 1)/this._pageSize)+1,
                            onClickNext: this.FetchNextPage,
                            onClickPrevious: this.FetchPreviousPage}}>
                        </ControlledListPagingNavigator>
                    </div>
                    : null}
            </FluentProvider>
        );
    }
};