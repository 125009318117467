import User from "./User";

export class DisableUserServiceResponseModel {

    id: string;
    error: {
        code: string,
        message: string,
    };
    user: User;
    constructor(Id: string, Error: {
        code: string,
        message: string,
    },User: User) {
        this.user = User;
        this.id = Id;
        this.error = Error;
    }
}

export const EmptyDisableUserServiceResponseModel = new DisableUserServiceResponseModel("", {
    code: "",
    message: ""
}, new User("", "", "", "", "", {
    email: "", city: null, country: null, department: "", jobTitle: null,
    officeLocation: null,
    postalCode: null,
    state: null,
    streetAddress: null,
    mobilePhone: null }, [""],[""], false, false));

export default DisableUserServiceResponseModel;