import React from 'react';
import * as Portal from "Components/Framework/Page/CommonPage";
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';

import {
    IColumn,
    IDetailsListProps,
    CheckboxVisibility,
    DetailsListLayoutMode,
    Selection,
    SelectionMode
} from 'office-ui-fabric-react/lib/DetailsList';
import { Link } from '@fluentui/react';
import { ChevronRight16Regular } from "@fluentui/react-icons";
import { ILocationFilterProps } from 'Components/Framework/List/LocationFilters';
import { Authorization, AuthorizedRole } from "Auth/Authorization";
import { Constants } from 'Utilities/Constants';
import { DeviceDetails } from './DeviceDetails';
import { DeviceInventoryService } from 'Services/DeviceInventoryService';
import { DeviceApiModel, DeviceMapper, DeviceUIModel, EmptyDeviceApiModel, EmptyDeviceUIModel } from 'Models/DeviceModel';
import { FilterableDetailsList } from 'Components/Framework/List/FilterableDetailsList';
import { ResourceStrings } from 'Resources/ResourceKeys';
import { TracingService } from 'Services/TracingService';
import { UserContext } from 'Auth/UserContext';

import ResourceManager from 'Resources/ResourceManager';
import Loading from 'Images/Loading.gif';
import { CSVLink } from 'react-csv';

const Component_Name = 'Device Inventory';
const ColumnName_DeviceAsset = ResourceManager.GetString(ResourceStrings.Device.AssetId);
const ColumnName_AutoPilotRegistrationDate = ResourceManager.GetString(ResourceStrings.Device.AutoPilotRegistrationDate);
const ColumnName_Type = ResourceManager.GetString(ResourceStrings.Device.Scenario);
const ColumnName_DeviceState = ResourceManager.GetString(ResourceStrings.Device.DeviceState);
const ColumnName_Campus = ResourceManager.GetString(ResourceStrings.Locations.Campus);
const ColumnName_Metro = ResourceManager.GetString(ResourceStrings.Locations.Metro);
const ColumnName_Facility = ResourceManager.GetString(ResourceStrings.Locations.Facility);
const ColumnName_Room = ResourceManager.GetString(ResourceStrings.Locations.Room);
const ColumnName_Locker = ResourceManager.GetString(ResourceStrings.Locations.Locker);
const ColumnName_Slot = ResourceManager.GetString(ResourceStrings.Locations.Slot);
const ColumnName_SerialNumber = ResourceManager.GetString(ResourceStrings.Device.SerialNumber);
const Alert_UnAuthorized = ResourceManager.GetString(ResourceStrings.Device.Unauthorized);
const Alert_NoDevices = ResourceManager.GetString(ResourceStrings.Device.NoDevicesFound);
const headers = [
    { label: ColumnName_SerialNumber, key: ResourceManager.GetString(ResourceStrings.DeviceKey.SerialNumber) },
    { label: ColumnName_DeviceAsset, key: ResourceManager.GetString(ResourceStrings.DeviceKey.AssetId) },
    { label: ColumnName_Type, key: ResourceManager.GetString(ResourceStrings.DeviceKey.Scenario) },
    { label: ColumnName_DeviceState, key: ResourceManager.GetString(ResourceStrings.DeviceKey.DeviceStatus) },
    { label: ColumnName_AutoPilotRegistrationDate, key: ResourceManager.GetString(ResourceStrings.DeviceKey.AutoPilotRegistrationDate) },
    { label: ColumnName_Metro, key: ResourceManager.GetString(ResourceStrings.DeviceKey.Metro) },
    { label: ColumnName_Campus, key: ResourceManager.GetString(ResourceStrings.DeviceKey.Campus) },
    { label: ColumnName_Facility, key: ResourceManager.GetString(ResourceStrings.DeviceKey.Facility) },
    { label: ColumnName_Room, key: ResourceManager.GetString(ResourceStrings.DeviceKey.Room) },
    { label: ColumnName_Locker, key: ResourceManager.GetString(ResourceStrings.DeviceKey.Locker) },
    { label: ColumnName_Slot, key: ResourceManager.GetString(ResourceStrings.DeviceKey.Slot) }
];

export interface ISortingProperties {
    compareDesc: (a: any, b: any) => number,
    compareAsc: (a: any, b: any) => number
}

export interface IDeviceInventoryState {
    items: DeviceUIModel[],
    exportItems: DeviceUIModel[],
    filters: { key: string, value: string }[];
    columns: IColumn[];
    selectedDevice: DeviceApiModel;
    selectedItemShouldOpen: boolean;
    isLoading: boolean;

    //total number of records
    totalRecords: number;
    fromCount: number;
    toCount: number;
    showPrev: boolean;
    showNext: boolean;
}

let tracingService = TracingService.getInstance();
let deviceInventoryService = DeviceInventoryService.getInstance();
const constants = Constants.getInstance();

export class DeviceInventoryClassic extends React.Component<{}, IDeviceInventoryState> implements Portal.ICommonPage
{
    // Required member for a page
    public pageProps: Portal.CommonPageProps = {
        authRequired: true,
        pageTitle: "Device Inventory",
        authorizedRoles: [
            AuthorizedRole.OrganizationAdministrator,
            AuthorizedRole.SiteAdministrator,
            AuthorizedRole.CapacityManager,
            AuthorizedRole.DeviceManager,
            AuthorizedRole.ScenarioChamp,
            AuthorizedRole.SupportUser
        ],
        announcement: {
            text: (<span>
                    You are viewing the classic device inventory experience.&nbsp;
                    <Link href="./DeviceInventory">Try the new experience <ChevronRight16Regular></ChevronRight16Regular></Link>
                </span>),
            closable: false
        }
    };

    private _alertMessage: string;
    private _allItems: DeviceUIModel[];
    private _allExportItems: DeviceUIModel[];
    private _columns: IColumn[];
    private _selectedItems: Selection;
    private fileName = "Device Inventory.csv";

    // Continuation token 
    private contToken: string = "";

    // Cache the fetched records 
    private pageCache = new Map<number, DeviceApiModel[]>();

    // Current page
    private pageCount: number = 0;

    constructor(props: {}) 
    {
        super(props);

        this._alertMessage = Alert_NoDevices;
        this._allItems = [];
        this._allExportItems = [];
        this._columns = this.GetListColumns();

        this._selectedItems = new Selection({
            onSelectionChanged: () => {
                if (this._selectedItems.count === 1) {
                    const item = this._selectedItems.getSelection()[0] as DeviceUIModel;                    
                    const cachedDevice = this.GetDeviceApiModelFromCache(item);
                    this.setState({ selectedDevice: cachedDevice });
                }
            }
        });

        this.state = {
            filters: [],
            items: this._allItems,
            columns: this._columns,
            selectedDevice: EmptyDeviceApiModel,
            selectedItemShouldOpen: false,
            exportItems: this._allExportItems,
            isLoading: true,
            totalRecords: 0,
            fromCount: 0,
            toCount: 0,
            showPrev: false,
            showNext: false
        };
    }

    //Can be moved to a common helper between components.
    getColumnKey = (ColumnName: string) => {
        return ColumnName.split(" ").join("");
    };

    private Constants = Constants.getInstance();

    static contextType = UserContext;

    private Authorize(user: any): boolean {
        let userHasDevicePermissions = Authorization.AuthorizeUser(user.roles, [
            AuthorizedRole.OrganizationAdministrator,
            AuthorizedRole.SiteAdministrator,
            AuthorizedRole.CapacityManager,
            AuthorizedRole.DeviceManager,
            AuthorizedRole.ScenarioManager,
            AuthorizedRole.ScenarioOwner,
            AuthorizedRole.ScenarioChamp,
            AuthorizedRole.SupportUser
        ]);

        return user.IdToken && userHasDevicePermissions;
    }
    /// <summary>
    /// Fetch devices from API and cache them.
    /// </summary>
    private FetchDevicesAsync = async (token: any) => {
        await deviceInventoryService.GetDeviceInventoryData(token, this.state.filters, this.contToken, constants.PageSize.toString()).then(results => {
            this.setState({ items: DeviceInventoryService.ApiDevicesToUIDevices(results.devices).sort((a, b) => (a.deviceStatus < b.deviceStatus) ? 1 : -1) });
            this.contToken = results.countiToken;
            this.pageCache.set(this.pageCount, results.devices);
            this.setState({ totalRecords: results.totalRecords });
        });
    };

    /// <summary>
    /// Loads first page of devices from API. Device cache and paging is reset before loading.
    /// </summary>
    private RefreshDevicesAsync = async () => {
        const user = this.context;

        if (this.Authorize(user)) {
            tracingService.trace(Component_Name, 'Authorized device management role, now fetching device Inventory data');

            // Reset paging properties and cache
            this.setState({ isLoading: true });
            this.pageCount = 1;
            this.contToken = "";
            this.pageCache = new Map<number, DeviceApiModel[]>();
            this.setState({ totalRecords: 0 });

            await this.FetchDevicesAsync(user.IdToken);
            this.setState({ fromCount: 1 });
            this.setState({ toCount: this.state.totalRecords < constants.PageSize ? this.state.totalRecords : constants.PageSize });
            this.setState({ showPrev: false });

            if (this.contToken !== null) {
                this.setState({ showNext: true });
            }

            this.setState({ isLoading: false });
            this.GetExportData("","");
        }
        else {
            tracingService.trace(Component_Name, 'Not authorized  as siteAdministrator or capacityManager');
            this._alertMessage = Alert_UnAuthorized;
            this.setState({ isLoading: false });
        }
    };

    /// <summary>
    /// Loads next page of devices from API or cache.
    /// </summary
    private GetDevicesNextAsync = async () => {
        const user = this.context;
        
        if (this.Authorize(user)) {
            tracingService.trace(Component_Name, 'Authorized device management role, now fetching device Inventory data');
            this.setState({ isLoading: true });

            this.pageCount++;
            this.pageCache.has(this.pageCount) ? this.setState({ items: this.GetCurrentPageItemsFromCache() }) : await this.FetchDevicesAsync(user.IdToken);

            this.setState({ fromCount: ((this.pageCount - 1) * constants.PageSize) + 1 });
            this.setState({ toCount: this.state.totalRecords < this.pageCount * constants.PageSize ? this.state.totalRecords : this.pageCount * constants.PageSize });
            this.setState({ showPrev: true });

            if (this.contToken === null && this.pageCount >= this.pageCache.size) {
                this.setState({ showNext: false });
            }
            this.setState({ isLoading: false });
        }
        else {
            tracingService.trace(Component_Name, 'Not authorized for device management');
            this._alertMessage = Alert_UnAuthorized;
            this.setState({ isLoading: false });
        }
    };

    /// <summary>
    /// Loads previous page of devices from cache.
    /// </summary
    private GetDevicesPrevAsync = async () => {
        this.pageCount--;
        this.setState({ fromCount: ((this.pageCount - 1) * constants.PageSize) + 1 });
        this.setState({ toCount: this.state.totalRecords < this.pageCount * constants.PageSize ? this.state.totalRecords : this.pageCount * constants.PageSize });
        this.setState({ items: this.GetCurrentPageItemsFromCache() });
        this.setState({ isLoading: false });

        if (this.pageCount === 1) {
            this.setState({ showPrev: false });
        }

        this.setState({ showNext: true });
    };

    /// <summary>
    /// Returns cached items for the current page.
    /// </summary
    private GetCurrentPageItemsFromCache() {
        return DeviceInventoryService.ApiDevicesToUIDevices(this.pageCache.get(this.pageCount)!).sort((a, b) => (a.deviceStatus < b.deviceStatus) ? 1 : -1);
    }

    /// <summary>
    /// Fetches all devices from API for export.
    /// </summary
    private GetExportData = async (continuationToken: string, pageSize: string) => {
        const user = this.context;
        const results = await deviceInventoryService.GetDeviceInventoryData(user.IdToken, this.state.filters, continuationToken, pageSize);
        if (results.countiToken !== null) {
           this.setState(preState => ({
                exportItems: [...preState.exportItems, ...DeviceInventoryService.ApiDevicesToUIDevices(results.devices)]
            }));
            this.GetExportData(results.countiToken, "");
        } else {
            this.setState(preState => ({
                exportItems: [...preState.exportItems, ...DeviceInventoryService.ApiDevicesToUIDevices(results.devices)]
            }));
        }
    };

    private OnStatusColumnHeaderClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        if (!column.isSortedDescending) {
            this.setState((prevState) => ({
                items: prevState.items.sort((a, b) => (a.deviceStatus < b.deviceStatus) ? 1 : -1),
                columns: prevState.columns.map(col => {
                    if (col.key === column.key) {
                        col.isSortedDescending = !col.isSortedDescending;
                        col.isSorted = true;
                    } else {
                        col.isSorted = false;
                    }
                    return col;
                })         
            }));
        } else {
            this.setState((prevState) => ({
                items: prevState.items.sort((a, b) => (b.deviceStatus < a.deviceStatus) ? 1 : -1),
                columns: prevState.columns.map(col => {
                    if (col.key === column.key) {
                        col.isSortedDescending = !col.isSortedDescending;
                        col.isSorted = true;
                    } else {
                        col.isSorted = false;
                    }
                    return col;
                })
            }));
        }

    };

    private OnSerialNumColumnHeaderClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        if (!column.isSortedDescending) {
            this.setState((prevState) => ({
                items: prevState.items.sort((a, b) => (a.deviceSerialNumber < b.deviceSerialNumber) ? -1 : 1),
                columns: prevState.columns.map(col => {
                    if (col.key === column.key) {
                        col.isSortedDescending = !col.isSortedDescending;
                        col.isSorted = true;
                    } else {
                        col.isSorted = false;
                    }
                    return col;
                })
            }));
        } else {
            this.setState((prevState) => ({
                items: prevState.items.sort((a, b) => (b.deviceSerialNumber < a.deviceSerialNumber) ? -1 : 1),
                columns: prevState.columns.map(col => {
                    if (col.key === column.key) {
                        col.isSortedDescending = !col.isSortedDescending;
                        col.isSorted = true;
                    } else {
                        col.isSorted = false;
                    }
                    return col;
                })
            }));
        }
    };

    private OnAPRegistrationDateColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        if (!column.isSortedDescending) {
            this.setState({
                items: this.state.items.sort((a, b) => (a.autoPilotRegistrationDate < b.autoPilotRegistrationDate) ? -1 : 1),
                columns: this.state.columns.map(col => {
                    if (col.key === column.key) {
                        col.isSortedDescending = !col.isSortedDescending;
                        col.isSorted = true;
                    } else {
                        col.isSorted = false;
                    }
                    return col;
                })

            });
        } else {
            this.setState({
                items: this.state.items.sort((a, b) => (b.autoPilotRegistrationDate < a.autoPilotRegistrationDate) ? -1 : 1),
                columns: this.state.columns.map(col => {
                    if (col.key === column.key) {
                        col.isSortedDescending = !col.isSortedDescending;
                        col.isSorted = true;
                    } else {
                        col.isSorted = false;
                    }
                    return col;
                })
            });
        }
    };

    private CloseSelectedItem = (ev: React.MouseEventHandler<HTMLDivElement>) => {
        this.setState({
            selectedDevice: EmptyDeviceApiModel,
            selectedItemShouldOpen: false
        });
    };

    private OnRowClicked = (item: DeviceUIModel): void => {
        // look up device id in cache
        const clickedDevice = this.GetDeviceApiModelFromCache(item);
        if (clickedDevice !== this.state.selectedDevice && !this.state.selectedItemShouldOpen) {
            this.setState({
                selectedDevice: clickedDevice,
                selectedItemShouldOpen: true
            });
        }
        else {
            this.setState({ selectedItemShouldOpen: false });
        }
    };

    private GetDeviceApiModelFromCache(uiDevice: DeviceUIModel): DeviceApiModel {
        const cachedDevice = this.pageCache.get(this.pageCount)!.find((device) => device.serialNumber === uiDevice.deviceSerialNumber &&
                device.assetId === uiDevice.deviceAssetId
        );

        return cachedDevice! ?? EmptyDeviceApiModel;
    }

    private locationFilterCallback = (selectedCampusItem: string, selectedFacilityItem: string, selectedRoomItem: string, selectedMetroItem: string, selectedScenarioItem: string) => {
        if (selectedMetroItem === '' && selectedScenarioItem === '') {
            console.log("Clearing filters");
            this.setState({
                filters: []
            }, this.RefreshDevicesAsync);
        }
        else if (selectedScenarioItem !== '' && selectedMetroItem === '') {
            this.setState({
                filters: [{ key: this.Constants.DeviceLocationScenario, value: selectedScenarioItem }]
            }, this.RefreshDevicesAsync);
        }
        else {
            this.setState({
                filters: [{ key: this.Constants.DeviceLocationMetro, value: selectedMetroItem },
                { key: this.Constants.DeviceLocationCampus, value: selectedCampusItem },
                { key: this.Constants.DeviceLocationFacility, value: selectedFacilityItem },
                { key: this.Constants.DeviceLocationRoom, value: selectedRoomItem },
                { key: this.Constants.DeviceLocationScenario, value: selectedScenarioItem }
                ]
            }, this.RefreshDevicesAsync);
        }
        console.log(this.state.filters);

    };

    private GetListColumns() {
        return [
            {
                key: this.getColumnKey(ColumnName_SerialNumber),
                name: ColumnName_SerialNumber,
                fieldName: 'deviceSerialNumber',
                headerClassName: 'dark-hover',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isMultiline: true,
                isSorted: false,
                isSortedDescending: false,
                onColumnClick: this.OnSerialNumColumnHeaderClick
            },
            {
                key: this.getColumnKey(ColumnName_DeviceAsset),
                name: ColumnName_DeviceAsset,
                fieldName: 'deviceAssetId',
                headerClassName: 'dark-hover',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isMultiline: true
            },
            {
                key: this.getColumnKey(ColumnName_Type),
                name: ColumnName_Type,
                fieldName: 'deviceType',
                headerClassName: 'dark-hover',
                minWidth: 100,
                maxWidth: 150,
                isResizable: true,
                isMultiline: true
            },
            {
                key: this.getColumnKey(ColumnName_DeviceState),
                name: ColumnName_DeviceState,
                fieldName: 'deviceStatus',
                headerClassName: 'dark-hover',
                minWidth: 100,
                maxWidth: 200,
                isResizable: true,
                isMultiline: true,
                isSorted: true,
                isSortedDescending: false,
                onColumnClick: this.OnStatusColumnHeaderClick
            }, 
            {
                key: this.getColumnKey(ColumnName_AutoPilotRegistrationDate),
                name: ColumnName_AutoPilotRegistrationDate,
                fieldName: 'apRegistrationDateUTCString',
                headerClassName: 'dark-hover',
                minWidth: 100,
                maxWidth: 200,
                isResizable: true,
                isMultiline: true,
                isSorted: true,
                isSortedDescending: false,
                onColumnClick: this.OnAPRegistrationDateColumnClick
            },
            {
                key: this.getColumnKey(ColumnName_Campus),
                name: ColumnName_Campus,
                fieldName: 'campus',
                headerClassName: 'dark-hover',
                minWidth: 100,
                maxWidth: 150,
                isResizable: true,
                isMultiline: true
            },
            {
                key: this.getColumnKey(ColumnName_Metro),
                name: ColumnName_Metro,
                fieldName: 'metro',
                headerClassName: 'dark-hover',
                minWidth: 100,
                maxWidth: 150,
                isResizable: true,
                isMultiline: true
            },
            {
                key: this.getColumnKey(ColumnName_Facility),
                name: ColumnName_Facility,
                fieldName: 'facility',
                headerClassName: 'dark-hover',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isMultiline: true
            },
            {
                key: this.getColumnKey(ColumnName_Room),
                name: ColumnName_Room,
                fieldName: 'room',
                headerClassName: 'dark-hover',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isMultiline: true
            },
            {
                key: this.getColumnKey(ColumnName_Locker),
                name: ColumnName_Locker,
                fieldName: 'locker',
                headerClassName: 'dark-hover',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isMultiline: true
            },
            {
                key: this.getColumnKey(ColumnName_Slot),
                name: ColumnName_Slot,
                fieldName: 'slot',
                headerClassName: 'dark-hover',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isMultiline: true
            }
        ];
    }

    async componentDidMount() {
        this.RefreshDevicesAsync();
    }

    private renderListWithControls(): JSX.Element {
        const { items } = this.state;
        const inventoryListProps: IDetailsListProps = {
            items: items,
            columns: this._columns,
            setKey: "set",
            selection: this._selectedItems,
            selectionMode: SelectionMode.none,
            layoutMode: DetailsListLayoutMode.justified,
            checkboxVisibility: CheckboxVisibility.hidden,
            onActiveItemChanged: this.OnRowClicked
        };

        const inventoryFilterProps: ILocationFilterProps = {
            toCallBack: this.locationFilterCallback
        };

        return (
            <Fabric>
                <div>
                    <CSVLink style={{ float: "right", width: "relative" }} data={this.state.exportItems} filename={this.fileName} headers={headers} target="_blank" >
                    <button className="buttonSD-primary" style={{ border: '0px', font: 'Segoe UI', fontSize: '16px', fontWeight: 500, padding: '0.5rem' }} >Export </button></CSVLink>
                </div>
                <br style={{clear: 'right'}}></br>
                <FilterableDetailsList listProps={inventoryListProps} filterProps={inventoryFilterProps} />
                <button className="device-health-item-btn" onClick={this.GetDevicesPrevAsync} disabled={this.state.showPrev === false}>
                    Previous
                </button>
                <button className="device-health-item-btn" onClick={this.GetDevicesNextAsync} disabled={this.state.showNext === false}>
                    Next
                </button>
                <div style={{ float: "left" }}><label> Showing {this.state.fromCount} to {this.state.toCount} of {this.state.totalRecords} records</label></div>
                <img src={Loading} hidden={!this.state.isLoading} style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} alt="Loading"></img>
                <div style={{ textAlign: 'center' }} hidden={this.state.isLoading || items.length > 0}>
                    <h5>{this._alertMessage}</h5>
                </div>
            </Fabric>
        );
    }

    private renderDeviceDetailsPage(): JSX.Element {
        const user = this.context;
        return (
            <Fabric>
                <div style={{ position: 'relative', width: '100%' }}>
                    <DeviceDetails device={this.state.selectedDevice} onClose={this.CloseSelectedItem} />
                </div>
            </Fabric>
        );
    }

    public render(): JSX.Element {
        return (
            <Portal.CommonPage {...this.pageProps}>
                    <Fabric>
                        <div className='device-health-container device-health-component'>
                            {this.state.selectedItemShouldOpen ? this.renderDeviceDetailsPage() : this.renderListWithControls()}
                        </div>
                    </Fabric>
            </Portal.CommonPage>
        );
    }
}