import * as React from 'react';
import { Dropdown, IDropdownStyles, IDropdownOption } from 'office-ui-fabric-react/lib/Dropdown';
import { IStackTokens, Stack } from 'office-ui-fabric-react/lib/Stack';
import { PrimaryButton, Button } from 'office-ui-fabric-react';

import { ApplicationInsightsLogger } from 'Services/Logger';
import { Authorization, AuthorizedRole } from "Auth/Authorization";
import { Constants } from 'Utilities/Constants';
import { LocationService } from 'Services/LocationService';
import { ScenarioService } from 'Services/ScenarioService';
import { LocationModel } from 'Models/LocationModel';
import { ResourceStrings } from 'Resources/ResourceKeys';
import { UserContext } from 'Auth/UserContext';
import ResourceManager from 'Resources/ResourceManager';
import 'scss/site.css';
import { ScenarioResponseModel } from '../../../Models/ScenarioResponseModel';

const ScenarioFilter_ScenarioName = ResourceManager.GetString(ResourceStrings.Scenarios.Scenario);
const LcoationFilter_MetroName = ResourceManager.GetString(ResourceStrings.Locations.Metro);
const LcoationFilter_CampusName = ResourceManager.GetString(ResourceStrings.Locations.Campus);
const LcoationFilter_FacilityName = ResourceManager.GetString(ResourceStrings.Locations.Facility);
const LcoationFilter_roomName = ResourceManager.GetString(ResourceStrings.Locations.Room);
const SearchButton = ResourceManager.GetString(ResourceStrings.Common.Search);
const ClearButton = "Clear";
const GeneralErrorMessage = ResourceManager.GetString(ResourceStrings.Common.GeneralErrorMessage);
const ErrorEmailText= 'mailto:' + Constants.getInstance().getSupportEmail + '?subject=Error encountered, correlation ID:' + Constants.getInstance().CorrelationID;

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 200 },
    dropdownItems: { color: "black" }
};

export interface ILocationFilterProps {
    toCallBack: (selectedCampusItem: string, selectedFacilityItem: string, selectedroomItem: string, selectedMetroItem: string, selectedScenarioItem: string) => void;
}

interface ILocationFiltersState {
    selectedCampusItem?: { key: string | number , text: string};
    selectedroomItem?: { key: string | number, text: string };
    selectedMetroItem?: { key: string | number, text: string };
    selectedFacilityItem?: { key: string | number, text: string };
    selectedScenarioItem?: { key: string | number, text: string };
    allLocations: LocationModel[];
    metroData: IDropdownOption[];
    campusData: IDropdownOption[];
    facilityData: IDropdownOption[];
    roomData: IDropdownOption[];
    scenarioData: IDropdownOption[];
    searchEnabled: boolean
    isError: boolean;
}
const stackTokens: IStackTokens = { childrenGap: 20 };

export class LocationFilters extends React.Component<ILocationFilterProps, ILocationFiltersState> {
    static contextType = UserContext;
    private userIsScenarioReader = this.AuthorizeScenarioReader();

    private getLocationData = async () => {
        const user = this.context;

        await LocationService.getInstance().GetLocationData(user.IdToken).then(results => {
            if (results && !results?.hasOwnProperty("error")) {
                this.setfilter(results);
                this.setState({
                    isError: false
                });
            } else {
                this.setState({
                    isError: true
                });
            }
        });
    };

    private getScenarioData = async () => {
        const user = this.context;

        await ScenarioService.getInstance().GetScenariosV1Async(user.IdToken).then(results => {
            if (results && !results?.hasOwnProperty("error")) {
                this.setScenario(results);
                this.setState({
                    isError: false
                });
            } else {
                this.setState({
                    isError: true
                });
            }
        });
    };

    public state: ILocationFiltersState = {
        selectedCampusItem: undefined,
        selectedFacilityItem: undefined,
        selectedroomItem: undefined,
        selectedMetroItem: undefined,
        selectedScenarioItem: undefined,
        allLocations: [],
        metroData: [],
        campusData: [],
        facilityData: [],
        roomData: [],
        scenarioData: [],
        searchEnabled: false,
        isError: false
    };

    async componentDidMount() {
        this.getLocationData();
        if (this.userIsScenarioReader) {
            this.getScenarioData();
        }
    }

    private setfilter(locations: LocationModel[]) {
        const { metroData } = this.state;

        locations.map((metro) => {
            if (metroData.findIndex(x => x.key === metro.id) === -1)
                metroData.push({ key: metro.id, text: metro.name });
        });

        this.setState({
            allLocations: locations,
            metroData: metroData
        });
    }

    private setScenario(scenarios: ScenarioResponseModel[]) {
        const { scenarioData } = this.state;

        scenarios.map((scenario) => {
            if (scenarioData.findIndex(x => x.key === scenario.id) === -1)
                scenarioData.push({ key: scenario.id, text: scenario.name });
        });

        this.setState({
            scenarioData: scenarioData
        });
    }

    private AuthorizeScenarioReader(): boolean {
        const user = this.context;
        return Authorization.AuthorizeUser(user.roles, [
            AuthorizedRole.OrganizationAdministrator,
            AuthorizedRole.SiteAdministrator,
            AuthorizedRole.CapacityManager,
            AuthorizedRole.DeviceManager,
            AuthorizedRole.ScenarioManager
        ]);
    }

    public render() {
        const { selectedCampusItem, selectedFacilityItem, selectedroomItem, selectedMetroItem, selectedScenarioItem, campusData, facilityData, roomData, metroData, scenarioData, searchEnabled } = this.state;

        return (
            <div>
                <Stack horizontal verticalAlign="end" >
                    <div hidden={!this.state.isError} className='row'>
                        <h6 style={{
                            color: "#4c4cff"
                        }}> {GeneralErrorMessage} </h6>
                        <a href={ErrorEmailText} target="_blank" rel="noreferrer"> {Constants.getInstance().getSupportEmail} </a>
                    </div>
                </Stack>
                <Stack horizontal tokens={stackTokens} verticalAlign="end" style={{ marginBottom: '20px' }}>

                    <Dropdown
                        label={ScenarioFilter_ScenarioName}
                        selectedKey={selectedScenarioItem ? selectedScenarioItem.key : null}
                        onChange={this._onChangeScenario}
                        placeholder="Select an option"
                        options={scenarioData}
                        styles={dropdownStyles}
                        isDisabled={scenarioData.length === 0 || !this.userIsScenarioReader}
                    />
                    <Dropdown
                        label={LcoationFilter_MetroName}
                        selectedKey={selectedMetroItem ? selectedMetroItem.key : null}
                        onChange={this._onChangeMetro}
                        placeholder="Select an option"
                        options={metroData}
                        styles={dropdownStyles}
                        isDisabled={metroData.length === 0}
                    />
                    <Dropdown
                        label={LcoationFilter_CampusName}
                        selectedKey={selectedCampusItem ? selectedCampusItem.key : null}
                        onChange={this._onChangeCampus}
                        placeholder="Select an option"
                        options={campusData}
                        styles={dropdownStyles}
                        isDisabled={campusData.length === 0 || selectedMetroItem === undefined}
                    />
                    <Dropdown
                        label={LcoationFilter_FacilityName}
                        selectedKey={selectedFacilityItem ? selectedFacilityItem.key : null}
                        onChange={this._onChangeFacility}
                        placeholder="Select an option"
                        options={facilityData}
                        styles={dropdownStyles}
                        isDisabled={facilityData.length === 0 || selectedCampusItem === undefined}
                    />
                    <Dropdown
                        label={LcoationFilter_roomName}
                        selectedKey={selectedroomItem ? selectedroomItem.key : null}
                        onChange={this._onChangeroom}
                        placeholder="Select an option"
                        options={roomData}
                        styles={dropdownStyles}
                        isDisabled={roomData.length === 0 || selectedFacilityItem === undefined}
                    />
                    <PrimaryButton className="buttonSD-primary" text={SearchButton} onClick={this.onSetFocus} disabled={!searchEnabled} />
                    <Button className="buttonSD-secondary" text={ClearButton} onClick={this._onClearFilters} disabled={!searchEnabled} />
                </Stack>
            </div>
        );
    }

    private _onChangeMetro = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void => {
        if (item !== undefined) {

            var campusDataNew: IDropdownOption[] = [];
            try {
                this.state.allLocations.filter(x => x.id === item.key)
                    .map((metro) => {
                        metro.children.map((campus) => {
                            campusDataNew.push({ key: campus.id, text: campus.name });
                        });
                    });
            }
            catch (error) {
                ApplicationInsightsLogger.getInstance().logException(error);
                this.setState({ isError: true });
            }

            this.setState({
                selectedMetroItem: item,
                searchEnabled: true,
                selectedCampusItem: undefined,
                selectedFacilityItem: undefined,
                selectedroomItem: undefined,
                campusData: campusDataNew,
                facilityData: [],
                roomData: []
            });
        }
    };

    private _onChangeScenario = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void => {

        if (item !== undefined) {

            this.setState({
                selectedScenarioItem: item,
                searchEnabled: true
            });
        };
    };

    private _onChangeCampus = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void => {
        if (item !== undefined) {

            var facilityDataNew: IDropdownOption[] = [];
            try {
                this.state.allLocations.filter(x => x.id === this.state.selectedMetroItem?.key)
                    .map((metro) => {
                        metro.children.filter(x => x.id === item.key)
                            .map((campus) => {
                                campus.children.map((facility) => {
                                    facilityDataNew.push({ key: facility.id, text: facility.name });
                                });
                            });
                    });
            } catch (error) {
                ApplicationInsightsLogger.getInstance().logException(error);
                this.setState({ isError: true });
            }

            this.setState({
                selectedCampusItem: item,
                facilityData: facilityDataNew,
                selectedFacilityItem: undefined,
                selectedroomItem: undefined,
                roomData: []
            });
        }
    };

    private _onChangeFacility = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void => {
        if (item !== undefined) {

            var roomDataNew: IDropdownOption[] = [];

            try {
                this.state.allLocations.filter(x => x.id === this.state.selectedMetroItem?.key)
                    .map((metro) => {
                        metro.children.filter(x => x.id === this.state.selectedCampusItem?.key)
                            .map((campus) => {
                                campus.children.filter(x => x.id === item.key)
                                    .map((facility) => {
                                        facility.children.map((rooms) => {
                                            roomDataNew.push({ key: rooms.id, text: rooms.name });
                                        });
                                    });
                            });
                    });
            }
            catch (error) {
                ApplicationInsightsLogger.getInstance().logException(error);
                this.setState({ isError: true });
            }

            this.setState({
                selectedFacilityItem: item,
                roomData: roomDataNew,
                selectedroomItem: undefined
            });
        }
    };

    private _onChangeroom = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void => {
        if (item !== undefined) {
            this.setState({
                selectedroomItem: item
            });
        };
    };

    private _onClearFilters = () => {
        this.setState({
            selectedScenarioItem: undefined,
            selectedMetroItem: undefined,
            selectedCampusItem: undefined,
            selectedFacilityItem: undefined,
            selectedroomItem: undefined
        });

        this.props.toCallBack('', '', '', '', '');
    };

    private onSetFocus = () => {
        this.props.toCallBack(this.state.selectedCampusItem !== undefined && this.state.selectedCampusItem.key !== undefined ? this.state.selectedCampusItem.key.toString() : ''
            , this.state.selectedFacilityItem !== undefined && this.state.selectedFacilityItem.key !== undefined ? this.state.selectedFacilityItem.key.toString() : ''
            , this.state.selectedroomItem !== undefined && this.state.selectedroomItem.key !== undefined ? this.state.selectedroomItem.key.toString() : ''
            , this.state.selectedMetroItem !== undefined && this.state.selectedMetroItem.key !== undefined ? this.state.selectedMetroItem.key.toString() : ''
            , this.state.selectedScenarioItem !== undefined && this.state.selectedScenarioItem.key !== undefined ? this.state.selectedScenarioItem.key.toString() : '');
    };

}
