import * as React from "react";
import * as Portal from "Components/Framework/Page/CommonPage";
import type { PagingParams } from "Components/Framework/CommonList/ListPaging";

import { Constants } from "Utilities/Constants";
import { CommonList, CommonListCache, CommonListProps } from "Components/Framework/CommonList/CommonList";
import { DeviceApiModel, DeviceMapper, DeviceUIModel, EmptyDeviceApiModel, EmptyDeviceUIModel } from "Models/DeviceModel";
import { DeviceDetails } from './DeviceDetails';
import { DeviceInventoryService } from "Services/DeviceInventoryService";
import { ScenarioService } from "Services/ScenarioService";
import { TracingService } from "Services/TracingService";
import { Authorization, AuthorizedRole } from "Auth/Authorization";
import { UserContext } from 'Auth/UserContext';
import ResourceManager from "Resources/ResourceManager";
import { ResourceStrings } from "Resources/ResourceKeys";
import {
    Button,
    DataGridProps,
    TableColumnDefinition,
    createTableColumn
} from "@fluentui/react-components";
import { DismissRegular, ChevronRight12Regular } from "@fluentui/react-icons";
import { DynamicFilterDefinition, FilterValue } from "Components/Framework/CommonList/Filters/DynamicFilterTypes";
import { LocationFilterDefinition } from "Components/Framework/CommonList/Filters/LocationFilterDefinition";
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { Link } from '@fluentui/react';
import { ScenarioVariant, Scenario, ScenarioVariantType } from "../../../Models/ScenarioResponseModel";

const Component_Name = 'Device Inventory List';
const debugging = false;
const debug = (message: string) => {
    if (debugging) {
        console.log("[" + Component_Name + "] " + message);
    }
};

let tracingService = TracingService.getInstance();
let deviceInventoryService = DeviceInventoryService.getInstance();

let AlertMessage = ResourceManager.GetString(ResourceStrings.UpdateUserKeys.UnAuthorized);
let Error_Fetch = 'Error retrieving devices.'; // localize
let Error_Search = 'Enter at least 3 characters of serial number to begin search'; // localize
var scenarios: Scenario[];

interface IDeviceListState {
    items: any[];
    isLoading: boolean;
    isRefreshing: boolean;
    isError: boolean;
    totalItemCount: number;
    selectedItem: DeviceApiModel;
    selectedItemOpen: boolean;
    sortState: Parameters<NonNullable<DataGridProps["onSortChange"]>>[1];
    scenarioFilterOptions: FilterValue[];
    locationFilter: DynamicFilterDefinition;
    cacheFull: boolean;
    refreshToken: number;
};

export class DeviceInventory extends React.Component<{}, IDeviceListState> implements Portal.ICommonPage {
    // Required member for a page
    public pageProps: Portal.CommonPageProps = {
        authRequired: true,
        pageTitle: "Device Inventory",
        authorizedRoles: [
            AuthorizedRole.OrganizationAdministrator,
            AuthorizedRole.SiteAdministrator,
            AuthorizedRole.CapacityManager,
            AuthorizedRole.DeviceManager,
            AuthorizedRole.ScenarioChamp,
            AuthorizedRole.SupportUser
        ],
        announcement: {
            text: (<span>
                Welcome to the new device inventory experience! Not ready yet?&nbsp;
                <Link href="./DeviceInventoryClassic">Go to the classic experience <ChevronRight12Regular></ChevronRight12Regular></Link>
            </span>),
            closable: false
        }
    };

    private isError = false;
    private paging: PagingParams = {
        pageSize: 100,
        batchSize: 600,
        token: "",
        totalItemCount: 0
    };

    private deviceCache: CommonListCache = new CommonListCache();
    private activeFilters: { key: string, value: string }[] = [];
    private locationDef: LocationFilterDefinition;

    constructor(props: {}) {
        super(props);
        this.locationDef = new LocationFilterDefinition("device");

        this.state = {
            items: [],
            isLoading: true,
            isRefreshing: false,
            isError: false,
            totalItemCount: 0,
            selectedItem: EmptyDeviceApiModel,
            selectedItemOpen: false,
            sortState: {
                sortColumn: "serialNumber",
                sortDirection: "ascending"
            },
            scenarioFilterOptions: [],
            locationFilter: this.locationDef.createLocationFilterDefinition(3),
            cacheFull: false,
            refreshToken: 0
        };
    }

    static contextType = UserContext;

    private cacheIsFull(): boolean {
        return this.deviceCache.Read().length === this.paging.totalItemCount;
    };

    private columns: TableColumnDefinition<DeviceUIModel>[] = [
        createTableColumn<DeviceUIModel>({
            columnId: "serialNumber",
            renderHeaderCell: () => {
                return "Serial Number";
            },
            renderCell: (item) => {
                return (<Link onClick={() => this.OnItemClicked(item)}>{item.deviceSerialNumber}</Link>);
            },
            compare: (a: DeviceUIModel, b: DeviceUIModel) => {
                return a.deviceSerialNumber.toLowerCase() < b.deviceSerialNumber.toLowerCase() ? -1 : 1;
            }
        }),
        createTableColumn<DeviceUIModel>({
            columnId: "assetId",
            renderHeaderCell: () => {
                return "Asset Id";
            },
            renderCell: (item) => {
                return item.deviceAssetId;
            },
            compare: (a: DeviceUIModel, b: DeviceUIModel) => {
                return a.deviceAssetId.toLowerCase() < b.deviceAssetId.toLowerCase() ? -1 : 1;
            }
        }),
        createTableColumn<DeviceUIModel>({
            columnId: "scenario",
            renderHeaderCell: () => {
                return "Scenario";
            },

            renderCell: (item) => {
                return item.deviceType;
            },
            compare: (a: DeviceUIModel, b: DeviceUIModel) => {
                return a.deviceType.toLowerCase() < b.deviceType.toLowerCase() ? -1 : 1;
            }
        }),
        createTableColumn<DeviceUIModel>({
            columnId: "status",
            renderHeaderCell: () => {
                return "Status";
            },

            renderCell: (item) => {
                return item.deviceStatus;
            },
            compare: (a: DeviceUIModel, b: DeviceUIModel) => {
                return a.deviceStatus < b.deviceStatus ? -1 : 1;
            }
        }),
        createTableColumn<DeviceUIModel>({
            columnId: "registration",
            renderHeaderCell: () => {
                return "Registration Date";
            },

            renderCell: (item) => {
                return item.apRegistrationDateUTCString;
            },
            compare: (a: DeviceUIModel, b: DeviceUIModel) => {
                return a.autoPilotRegistrationDate < b.autoPilotRegistrationDate ? -1 : 1;
            }
        }),
        createTableColumn<DeviceUIModel>({
            columnId: "metro",
            renderHeaderCell: () => {
                return "Metro";
            },

            renderCell: (item) => {
                return item.metro;
            },
            compare: (a: DeviceUIModel, b: DeviceUIModel) => {
                return a.metro < b.metro ? -1 : 1;
            }
        }),
        createTableColumn<DeviceUIModel>({
            columnId: "campus",
            renderHeaderCell: () => {
                return "Campus";
            },

            renderCell: (item) => {
                return item.campus;
            },
            compare: (a: DeviceUIModel, b: DeviceUIModel) => {
                return a.campus < b.campus ? -1 : 1;
            }
        }),
        createTableColumn<DeviceUIModel>({
            columnId: "facility",
            renderHeaderCell: () => {
                return "Facility";
            },

            renderCell: (item) => {
                return item.facility;
            },
            compare: (a: DeviceUIModel, b: DeviceUIModel) => {
                return a.facility < b.facility ? -1 : 1;
            }
        })
    ];

    private setTenantPagingSettings() {
        const user = this.context;
        if (user.TenantId === Constants.getInstance().xdcTenantId) {
            this.paging.pageSize = 10;
            this.paging.batchSize = 40;
        }
    };

    private Authorize(user: any): boolean {
        return user.IdToken && Authorization.AuthorizeUser(user.roles, this.pageProps.authorizedRoles);
    };

    private GetCacheAsUIModel = () => {
        return DeviceInventoryService.ApiDevicesToUIDevices(this.deviceCache.Read());
    };

    //#region Async data fetching
    private FetchDevicesAsync = async (hardRefresh?: boolean) => {
        const user = this.context;
        if (this.Authorize(user)) {
            return deviceInventoryService.GetDeviceInventoryData(user.IdToken, this.activeFilters, hardRefresh ? "" : this.paging.token, this.paging.batchSize.toString())
                .then(results => {
                    this.paging.totalItemCount = results.totalRecords;
                    this.paging.token = results.countiToken;

                    return results.devices.sort((a, b) => (a.serialNumber < b.serialNumber) ? -1 : 1);
                })
                .catch(error => {
                    tracingService.trace(Component_Name, error);
                    // server returns error 409 conflict when all results have been returned because the continuation token is no longer valid (null).
                    // exception needs to be logged when server returns 409 but the cache is still less than the total records promised.
                    // ex: total records reported: 308, total records returned: 302, cache will never be considered "full" due to discrepency.
                    AlertMessage = Error_Fetch;
                    console.log(error);
                    this.isError = true;
                    return [];
                });
        }
        return [];
    };

    private RefreshListAsync = async () => {
        this.deviceCache.Clear();
        this.paging.token = "";
        this.setState({
            isLoading: true,
            isRefreshing: true,
            items: []
        });

        debug("RefreshListAsync: refreshing");
        await this.FetchDevicesAsync(true).then(devices => {
            if (devices.length > 0) {
                this.deviceCache.Append(devices);
            }

            this.setState({
                items: DeviceInventoryService.ApiDevicesToUIDevices(devices),
                totalItemCount: this.paging.totalItemCount,
                isLoading: false,
                isRefreshing: false,
                selectedItem: EmptyDeviceApiModel,
                selectedItemOpen: false,
                isError: this.isError,
                sortState: {
                    sortColumn: "serialNumber",
                    sortDirection: "ascending"
                },
                cacheFull: this.deviceCache.Read().length === this.paging.totalItemCount
            });
        });
    };

    private FetchNextCacheBatchAsync = async () => {
        if (this.deviceCache.Read().length < this.state.totalItemCount) {
            this.deviceCache.Append(await this.FetchDevicesAsync(false));
        }
        this.setState({ cacheFull: this.cacheIsFull() });
    };

    private async fetchScenariosAsync() {
        const user = this.context;
        scenarios = (await ScenarioService.getInstance().GetScenariosAsync(user.IdToken));


        this.setState({
            scenarioFilterOptions: ScenarioService.getInstance().GetScenarioData().map((scenario: { id: any; name: any; }) => {
                return { value: scenario.id, text: scenario.name } as FilterValue;
            })
        });
    };

    private async fetchLocationsAsync() {
        const user = this.context;
        this.locationDef.fetchLocationData(user.IdToken).then(res => {
            this.setState({
                locationFilter: this.locationDef.createLocationFilterDefinition(3)
            });
        });
    };

    //#endregion

    private async loadingDelay() {
        await new Promise(resolve => setTimeout(resolve, 500));
    };

    private doSearch = (value: string) => {
        // Cancelling the search forces a full refresh
        if (value === "") {
            this.RefreshListAsync();
        } else if (value.length < 3) {
            this.setState({ isError: true });
            AlertMessage = Error_Search;
        } else {
            this.setState({
                isLoading: true,
                isRefreshing: true,
                items: []
            });

            // Call API everytime
            this.loadingDelay().then(async res => {
                let foundDevices: DeviceApiModel[] = [];
                this.activeFilters.push({ key: Constants.getInstance().DeviceLocationSerialNumber, value: value });
                foundDevices = await this.FetchDevicesAsync(true);
                this.activeFilters = this.activeFilters.filter((filters) => filters.key !== Constants.getInstance().DeviceLocationSerialNumber);
                this.deviceCache.Append(foundDevices);

                this.paging.totalItemCount = foundDevices.length;
                this.setState({
                    items: DeviceInventoryService.ApiDevicesToUIDevices(foundDevices),
                    totalItemCount: this.paging.totalItemCount,
                    isLoading: false,
                    isRefreshing: false,
                    selectedItem: EmptyDeviceApiModel,
                    selectedItemOpen: false
                });
            });
        }
    };


    private OnFiltersUpdated = (filters: { key: string, value: string | any[] }[]) => {
        this.activeFilters = [];
        filters.forEach(filter => {
            if (filter.key === "location") {
                this.activeFilters = this.activeFilters.concat(LocationFilterDefinition.parseLocationFilterValue(filter));
            }
            else {
                this.activeFilters.push({ key: filter.key, value: filter.value.toString() });
            }
        });
        this.RefreshListAsync();
    };

    private CloseSelectedItem = (ev: React.MouseEventHandler<HTMLDivElement>) => {
        this.setState({
            selectedItem: EmptyDeviceApiModel,
            selectedItemOpen: false
        });
        this.RefreshListAsync();
    };

    private OnItemClicked = (item: DeviceUIModel): void => {
        const cachedResult = this.deviceCache.Read().filter((device) => (device as DeviceApiModel).serialNumber === item.deviceSerialNumber);
        if (cachedResult.length === 0) {
            cachedResult.push(EmptyDeviceApiModel);
        }
        
        if (cachedResult[0] !== this.state.selectedItem && !this.state.selectedItemOpen) {

            //Check if device has tagId assigned to it
            if (cachedResult[0].tagIds !== null && cachedResult[0].tagIds.length > 0) {
                //Get Variant Name from TagId to device if Device Scenario has variant with it
                const scenario = scenarios.filter(s => s.id === cachedResult[0].scenarioIds[0])[0];
                const variants = scenario.GetVariants(ScenarioVariantType.Device);
                if (variants.length > 0) {
                    var variant = variants[0];
                    var variantOptions = variant.GetOptions().sort((a, b) => a.text < b.text ? -1 : 1);
                    var variantOptionName = variantOptions.find(f => f.key === cachedResult[0].tagIds[0]);
                    if (variantOptionName !== undefined && variantOptionName !== null) {
                        cachedResult[0].hasVariant = true;
                        cachedResult[0].variantLabelName = variants[0].name;
                        cachedResult[0].variantOptionName = variantOptionName.text;
                    }
                }
            }

            this.setState({
                selectedItem: cachedResult[0],
                selectedItemOpen: true
            });
        }
        else {
            this.setState({ selectedItemOpen: false });
        }
    };

    private async delayFetchFromCache(statePostDelay: any) {
        await new Promise(resolve => setTimeout(resolve, 700)).then(res => this.setState(statePostDelay));
    };

    private onSortChange: DataGridProps["onSortChange"] = (e, nextSortState) => {
        this.paging.token = "";
        this.setState({
            isLoading: true,
            isRefreshing: true
        });

        // Sort the cache
        const columnIndex = this.columns.findIndex(col => col.columnId === nextSortState.sortColumn);
        const reverseCompare = (a: DeviceUIModel, b: DeviceUIModel) => {
            return this.columns[columnIndex].compare(a, b) * -1;
        };

        // Sort the UI items
        let sortedItems = DeviceInventoryService.ApiDevicesToUIDevices(this.deviceCache.Read());
        sortedItems = nextSortState.sortDirection === "ascending"
            ? sortedItems.sort(this.columns[columnIndex].compare)
            : sortedItems.sort(reverseCompare);

        // Sort the cache
        let oldCache = this.deviceCache.Read();
        this.deviceCache.Clear();
        oldCache.sort((a: DeviceApiModel, b: DeviceApiModel) => {
            return sortedItems.findIndex(item => item.deviceSerialNumber === a.serialNumber) < sortedItems.findIndex(item => item.deviceSerialNumber === b.serialNumber) ? -1 : 1;
        });

        this.deviceCache.Append(oldCache);

        const updatedState = {
            sortState: nextSortState,
            items: sortedItems,
            selectedItem: EmptyDeviceApiModel,
            selectedItemOpen: false,
            isError: this.isError,
            isLoading: false,
            isRefreshing: false
        };

        this.delayFetchFromCache(updatedState);
    };

    private CloseMessageBox = () => {
        AlertMessage = "trr";
        this.setState({
            isError: false
        });
    };

    componentDidMount() {
        // refresh the list state, devices, scenarios, etc
        this.fetchScenariosAsync();
        this.fetchLocationsAsync();
        this.RefreshListAsync();
    };

    render() {
        this.setTenantPagingSettings();

        const listProps: CommonListProps = {
            dataGridProps: {
                items: this.state.items,
                columns: this.columns,
                sortable: this.state.cacheFull,
                sortState: this.state.sortState,
                onSortChange: this.onSortChange
            },
            itemCountName: "devices",
            isLoading: this.state.isLoading,
            isRefreshing: this.state.isRefreshing,
            onRefresh: this.RefreshListAsync,
            refreshToken: this.state.refreshToken,
            pagingProps: {
                pageSize: this.paging.pageSize,
                totalCount: this.state.totalItemCount,
                onFetchNextBatch: this.FetchNextCacheBatchAsync,
                getCache: this.GetCacheAsUIModel
            },
            searchProps: {
                columnId: this.columns[0].columnId.toString(),
                useCache: true,
                onSearch: this.doSearch,
                placeholder: "Search serialNumber"
            },
            filterProps: {
                definitions: [
                    {
                        key: Constants.getInstance().DeviceLocationScenario,
                        label: "Scenario",
                        options: this.state.scenarioFilterOptions
                    },
                    this.state.locationFilter
                ],
                onFiltersUpdate: this.OnFiltersUpdated
            }
        };

        if (this.state.selectedItemOpen) {
            return (
                <Portal.CommonPage {...this.pageProps}>
                    <Fabric>
                        <div style={{ position: 'relative', width: '100%' }}>
                            <DeviceDetails device={this.state.selectedItem} onClose={this.CloseSelectedItem} />
                        </div>
                    </Fabric>
                </Portal.CommonPage>
            );
        }

        return (
            <Portal.CommonPage {...this.pageProps}>
                <div className="user-list-error" style={{ display: this.state.isError ? "block" : "none" }}>
                    <Button
                        style={{ float: 'right' }}
                        onClick={this.CloseMessageBox}
                        appearance="transparent"
                        icon={<DismissRegular />}
                        size="small"
                    />
                    {AlertMessage}
                </div>
                <CommonList {...listProps}></CommonList>
            </Portal.CommonPage>
        );
    };
}